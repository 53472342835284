export const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: 8,
    margin: 'left',
  },
  rulesModalContainer: {
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
  },
};
