import { Icon } from '@components';
import { useScreenSize } from '@hooks';
import { Flex } from '@styles';
import { func, node, object } from 'prop-types';
import React from 'react';

import { styles } from './SquareButton.styles';

const SquareButton = ({
  SVG,
  children,
  containerProps,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { isMobile } = useScreenSize();

  const containerStyles = {
    ...styles.buttonContainer,
    ...(isMobile && styles.buttonContainerMobile),
  };

  return (
    <Flex
      {...containerStyles}
      onClick={onClick}
      {...containerProps}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {SVG ? <Icon height={isMobile ? 18 : 21} SVG={SVG} /> : children}
    </Flex>
  );
};

SquareButton.defaultProps = {
  SVG: null,
  children: null,
  containerProps: {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

SquareButton.propTypes = {
  SVG: func,
  children: node,
  containerProps: object,
  onClick: func.isRequired,
  onMouseEnter: func,
  onMouseLeave: func,
};

export default SquareButton;
