import { useScreenSize } from '@hooks';
import { Flex, Typography } from '@styles';
import { number } from 'prop-types';
import React from 'react';

import {
  calculateCompletedLevelsIndicatorWidth,
  styles,
} from './LevelsIndicator.styles';

const LevelsBar = ({ allLevelsAmount, completedLevelsAmount }) => {
  const { isTabletLG } = useScreenSize();

  return (
    <Flex {...styles.levelsBar} {...(isTabletLG && styles.levelsBarMobile)}>
      <Flex
        {...styles.completedLevelsIndicator}
        width={calculateCompletedLevelsIndicatorWidth({
          allLevelsAmount,
          completedLevelsAmount,
        })}
      />
      <Typography
        {...styles.completedLevelsText}
        {...(isTabletLG && styles.completedLevelsTextMobile)}
      >
        {completedLevelsAmount}/{allLevelsAmount}
      </Typography>
    </Flex>
  );
};

LevelsBar.propTypes = {
  allLevelsAmount: number.isRequired,
  completedLevelsAmount: number.isRequired,
};

export default LevelsBar;
