import { VALIDATION } from '@constants';
import { object, ref } from 'yup';

import shapes from './shapes';

const schemas = {
  changePasswordForm: object().shape({
    newPassword: shapes.password.notOneOf(
      [ref('oldPassword')],
      VALIDATION.ERROR_MESSAGE.PASSWORD.MATCH
    ),
    oldPassword: shapes.password,
    repeatNewPassword: shapes.password
      .oneOf(
        [ref('newPassword'), null],
        VALIDATION.ERROR_MESSAGE.PASSWORD.DO_NOT_MATCH
      )
      .notOneOf([ref('oldPassword')], VALIDATION.ERROR_MESSAGE.PASSWORD.MATCH),
  }),
  emailFrom: object().shape({
    email: shapes.email,
  }),
  resetPasswordForm: object().shape({
    password: shapes.password,
    repeatPassword: shapes.password.oneOf(
      [ref('password'), null],
      VALIDATION.ERROR_MESSAGE.PASSWORD.DO_NOT_MATCH
    ),
  }),
  signInForm: object().shape({
    password: shapes.password.required(),
    username: shapes.text,
  }),
  signUpForm: object().shape({
    password: shapes.password.required(),
    repeatPassword: shapes.password
      .oneOf(
        [ref('password'), null],
        VALIDATION.ERROR_MESSAGE.PASSWORD.DO_NOT_MATCH
      )
      .required(),
    username: shapes.text,
  }),
};

export default schemas;
