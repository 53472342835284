/* eslint-disable sort-keys-fix/sort-keys-fix */
import { languages } from '@constants';
import { useScreenSize } from '@hooks';
import { authSlice } from '@store';
import { Button, Flex, theme, Typography } from '@styles';
import { object } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const LanguageSwitch = ({ containerProps }) => {
  const dispatch = useDispatch();
  const { isTabletSM } = useScreenSize();
  const { i18n } = useTranslation();
  const { language } = useSelector((state) => state.authSlice);

  const handleLanguageClick = (locale) => () => {
    dispatch(authSlice.actions.setLanguage(locale));

    i18n.changeLanguage(locale);
  };

  return (
    <Flex alignItems="center" gap={isTabletSM ? 0 : 4} {...containerProps}>
      <Button
        height={22}
        onClick={handleLanguageClick('ua')}
        variant="textButton"
      >
        <Typography
          color={
            language === 'ua'
              ? theme.colors.schoolBusYellow[100]
              : theme.colors.white[100]
          }
          variant={isTabletSM ? 'MS-regular-s18-lh22' : 'MS-bold-s18-lh22'}
        >
          {languages?.ua?.displayText}
        </Typography>
      </Button>
      <Typography
        color={theme.colors.white[100]}
        variant={isTabletSM ? 'MS-regular-s18-lh22' : 'MS-bold-s18-lh22'}
      >
        |
      </Typography>
      <Button
        height={22}
        onClick={handleLanguageClick('en')}
        variant="textButton"
      >
        <Typography
          color={
            language === 'en'
              ? theme.colors.schoolBusYellow[100]
              : theme.colors.white[100]
          }
          variant={isTabletSM ? 'MS-regular-s18-lh22' : 'MS-bold-s18-lh22'}
        >
          {languages?.en?.displayText}
        </Typography>
      </Button>
    </Flex>
  );
};

LanguageSwitch.propTypes = {
  containerProps: object,
};

LanguageSwitch.defaultProps = {
  containerProps: {},
};

export default LanguageSwitch;
