const theme = {
  colors: {
    alto: { 100: '#D7D7D7', 30: '#D9D9D9', 40: '#D1D1D1' },
    background: '#212121',
    backgroundLight: '#ffffff66',
    black: {
      100: '#000000',
      20: '#00000033',
      60: '#00000099',
      70: '#000000b3',
    },
    brightGreen: '#3ECD00',
    darkFern: '#12550D',
    darkLimeGreen: {
      100: '#2AB900',
      50: '#2AB90080',
    },
    doveGray: '#717171',
    galliano: '#D3B90F',
    gorse: '#FFE646',
    gray: '#848484',
    greenKelp: { 100: '#182617', 60: '#2A4628' },
    hunterGreen: {
      10: '#1321121a',
      100: '#132112',
      20: '#13211233',
      60: '#13211299',
      90: '#132112e6',
    },
    lemonChiffon: '#FFF7C7',
    limeGreen: '#36B814',
    mallard: '#1C3A1A',
    mercury: { 100: '#E6E6E6', 40: '#E7E7E7' },
    olive: '#847200',
    red: { 100: '#FF0000', 20: '#FF000033', 50: '#FF000080', 60: '#FF00004D' },
    schoolBusYellow: {
      10: '#FFDD001A',
      100: '#FFDD00',
      20: '#FFDD0033',
      60: '#FFDD0099',
    },
    scorpion: { 60: '#585858', 70: '#565656' },
    shiraz: '#C2082F',
    silver: { 100: '#CCCCCC', 60: '#CDCDCD' },
    silverChalice: { 100: '#ACACAC', 60: '#9D9D9D' },
    tundora: '#494949',
    vividYellow: {
      100: '#FFF504',
      50: 'rgba(255, 213, 0, 0.8)',
      70: '#FFD500',
      80: '#FFF504cc',
    },
    white: {
      10: '#ffffff1a',
      100: '#FFFFFF',
      20: '#ffffff33',
      30: '#FFFFFF4d',
      40: '#ffffff66',
      5: '#ffffff0D',
      50: '#FFFFFF80',
      60: '#FFFFFF99',
      70: '#ffffffb3',
      80: '#ffffffcc',
    },
    whiteLine: {
      100: '#4A4A4A',
      20: '#4A4A4A33',
    },
  },
};

export default theme;
