/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  isAudioPlaying: true,
};

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    reset: (state) => ({
      ...initialState,
      isAudioPlaying: state.isAudioPlaying,
    }),
    setIsAudioPlaying: (state, action) => {
      state.isAudioPlaying = action.payload;
    },
    setIsAudioTriggered: (state, action) => {
      state.isAudioTriggered = action.payload;
    },
  },
});

export const userSlicePersistedReducer = persistReducer(
  {
    key: 'userSlice',
    storage,
  },
  userSlice.reducer
);
