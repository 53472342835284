import { theme } from '@styles';

export const styles = {
  contentContainer: {
    alignItems: 'center',
    color: theme.colors.white[100],
    flexDirection: 'column',
    gap: 32,
    justifyContent: 'center',
    padding: '15px',
    width: '100%',
  },
  contentContainerMobile: {
    alignItems: 'flex-start',
    borderRadius: '24px',
    gap: 24,
  },
  modalContainer: {
    backdropFilter: 'blur(12px)',
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
  },
  ruleBlocksContainer: {
    alignItems: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  ruleBlocksContainerMobile: {
    gap: 16,
  },
  rulesList: {
    flexDirection: 'column',
    fontSize: '20px',
    fontStyle: 'normal',
    gap: 32,
    letterSpacing: '0.4px',
    lineHeight: 'normal',
    marginTop: 32,
  },
  rulesListMobile: {
    fontSize: '16px',
    gap: 16,
  },
  scrollableContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    height: '90%',
    overflow: 'scroll',
    padding: '65px 65px 25px',
  },
  scrollableContainerMobile: {
    padding: 0,
  },
  subRulesList: {
    padding: '0',
  },
  submitButton: {
    cursor: 'pointer',
    maxWidth: '100%',
    padding: '22px 47px',
    textDecoration: 'uppercase',
    width: 'max-content',
  },
};

export const getRulesListStyles = ({ isTabletSM }) => ({
  ...styles.rulesList,
  ...(isTabletSM && styles.rulesListMobile),
});
