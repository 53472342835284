/* eslint-disable sort-keys-fix/sort-keys-fix */
import BlueCross from '../../assets/svgs/nato/blue/blueCross.svg';
import BlueCrossedEllips from '../../assets/svgs/nato/blue/blueCrossedEllips.svg';
import BlueDot from '../../assets/svgs/nato/blue/blueDot.svg';
import BlueEllips from '../../assets/svgs/nato/blue/blueEllips.svg';
import BlueEllipsWithDot from '../../assets/svgs/nato/blue/blueEllipsWithDot.svg';
import RedRhombusCross from '../../assets/svgs/nato/red/redRhombusCross.svg';
import RedRhombusWithCrossedEllips from '../../assets/svgs/nato/red/redRhombusWithCrossedEllips.svg';
import RedRhombusWithDot from '../../assets/svgs/nato/red/redRhombusWithDot.svg';
import RedRhombusWithEllips from '../../assets/svgs/nato/red/redRhombusWithEllips.svg';
import RedRhombusWithEllipsAndDot from '../../assets/svgs/nato/red/redRhombusWithEllipsAndDot.svg';

/*
  IMPORTANT: All coordinates are in [longitude, latitude] format instead of the opposite
  This is due to GeoJSON and Mapbox GL JS standards
*/

const IS_TEST_MODE = false;

const MAP_CONTAINER_ID = 'map';

const MIN_ZOOM_FOR_MOBILE = 10.2;

// Starobilsk area, Luhansk Oblast
const DEFAULT_MAP_CONFIG = {
  container: MAP_CONTAINER_ID,
  style: process.env.REACT_APP_MAPTILER_STYLE_ID,
  // Map rotation
  bearing: 12,
  minZoom: 10.45,
  maxZoom: 16,
  center: [38.507469, 49.331477],
  // maxBounds: [
  //   [37.7037, 49.075],
  //   [39.277, 49.802],
  // ],
  attributionControl: false,
  geolocateControl: false,
  maptilerLogo: false,
  terrainControl: false,
};

const GEOJSON_LINE_TYPES = {
  GREY_DASHED: 'GREY_DASHED',
  YELLOW_DASHED: 'YELLOW_DASHED',
  YELLOW_SOLID: 'YELLOW_SOLID',
};

const GEOJSON_LINE_CONFIG_BY_TYPE = {
  GREY_DASHED: {
    'line-color': '#000000',
    'line-dasharray': [2, 4],
    'line-opacity': 0.7,
    'line-width': 1,
  },
  YELLOW_DASHED: {
    'line-color': '#FFF504',
    'line-dasharray': [2, 4],
    'line-width': 1,
  },
  YELLOW_SOLID: {
    'line-color': '#FFF504',
    'line-width': 1,
  },
};

// Match with CSS class namings
const SCENARIO_MARKER_TYPES = {
  ADDITIONAL_AVAILABLE: 'additional-available',
  ADDITIONAL_PASSED: 'additional-passed',
  CURRENT: 'current',
  MAINLINE_PASSED: 'mainline-passed',
  UNAVAILABLE: 'unavailable',
};

/*
  - levelNumber = scenario levelNumber in the list
  - lineTo -> the levelNumber of the scenario to which the line should be drawn 
  - belongsToMainLine -> "true" if the scenario is part of the main line (solid horizontal line)
*/
const SCENARIO_MARKERS_ARRAY_WITH_CONFIG = [
  {
    belongsToMainLine: true,
    coordinates: [38.152, 49.391],
    levelNumber: '1',
    lineTo: '2',
  },
  {
    coordinates: [38.162, 49.425],
    levelNumber: '1.2',
    lineTo: '1',
  },
  {
    belongsToMainLine: true,
    coordinates: [38.21, 49.394],
    levelNumber: '2',
    lineTo: '3',
  },
  {
    belongsToMainLine: true,
    coordinates: [38.266, 49.378],
    levelNumber: '3',
    lineTo: '4',
  },
  {
    coordinates: [38.2783, 49.416],
    levelNumber: '3.2',
    lineTo: '3',
  },
  {
    belongsToMainLine: true,
    coordinates: [38.323, 49.363],
    levelNumber: '4',
    lineTo: '5',
  },
  {
    coordinates: [38.312, 49.328],
    levelNumber: '4.2',
    lineTo: '4',
  },
  {
    belongsToMainLine: true,
    coordinates: [38.383, 49.35],
    levelNumber: '5',
    lineTo: '6',
  },
  {
    belongsToMainLine: true,
    coordinates: [38.4368, 49.34],
    levelNumber: '6',
    lineTo: '7',
  },
  {
    coordinates: [38.447, 49.374],
    levelNumber: '6.2',
    lineTo: '6',
  },
  {
    coordinates: [38.459, 49.4143],
    levelNumber: '6.3',
    lineTo: '6.2',
  },
  {
    belongsToMainLine: true,
    coordinates: [38.49, 49.339],
    levelNumber: '7',
    lineTo: '8',
  },
  {
    belongsToMainLine: true,
    coordinates: [38.55, 49.33],
    levelNumber: '8',
    lineTo: '9',
  },
  {
    coordinates: [38.5632, 49.3721],
    levelNumber: '8.2',
    lineTo: '8',
  },
  {
    belongsToMainLine: true,
    coordinates: [38.63, 49.331],
    levelNumber: '9',
    lineTo: '10',
  },
  {
    coordinates: [38.6213, 49.2955],
    levelNumber: '9.2',
    lineTo: '9',
  },
  {
    belongsToMainLine: true,
    coordinates: [38.71, 49.303],
    levelNumber: '10',
    lineTo: '11',
  },
  {
    coordinates: [38.72, 49.343],
    levelNumber: '10.2',
    lineTo: '10',
  },
  {
    belongsToMainLine: true,
    coordinates: [38.7899, 49.291],
    levelNumber: '11',
    lineTo: '12',
  },
  {
    belongsToMainLine: true,
    coordinates: [38.876, 49.281],
    // no lines from here
    levelNumber: '12',
  },
  {
    coordinates: [38.866, 49.245],
    levelNumber: '12.2',
    lineTo: '12',
  },
  {
    coordinates: [38.853, 49.206],
    levelNumber: '12.3',
    lineTo: '12.2',
  },
];

/*
  NATO markers with respective coordinates and sources for passed and not passed states
*/
const NATO_MARKERS_ARRAY_WITH_CONFIG = [
  // Between 1 and 2, top-left blue, 4 items, always constant
  {
    becomesPassedAfter: '0',
    coordinates: [38.17806043084977, 49.455165917448824],
    sourceAfterPassing: BlueCross,
    sourceBeforePassing: BlueCross,
  },
  {
    becomesPassedAfter: '0',
    coordinates: [38.201, 49.436],
    sourceAfterPassing: BlueCross,
    sourceBeforePassing: BlueCross,
  },
  {
    becomesPassedAfter: '0',
    coordinates: [38.229085130389194, 49.42536705817707],
    sourceAfterPassing: BlueCross,
    sourceBeforePassing: BlueCross,
  },
  {
    becomesPassedAfter: '0',
    coordinates: [38.23940935808582, 49.442148351635495],
    sourceAfterPassing: BlueCross,
    sourceBeforePassing: BlueCross,
  },
  // Between 1 and 2, 3 items always constant
  {
    becomesPassedAfter: '0',
    coordinates: [38.111, 49.374],
    sourceAfterPassing: BlueCross,
    sourceBeforePassing: BlueCross,
  },
  {
    becomesPassedAfter: '0',
    coordinates: [38.13847674326598, 49.36087907778571],
    sourceAfterPassing: BlueCrossedEllips,
    sourceBeforePassing: BlueCrossedEllips,
  },
  {
    becomesPassedAfter: '0',
    coordinates: [38.167, 49.3544],
    sourceAfterPassing: BlueCrossedEllips,
    sourceBeforePassing: BlueCrossedEllips,
  },
  // Between 2 and 3, 2 items which are not constant
  {
    becomesPassedAfter: '2',
    coordinates: [38.222, 49.368],
    sourceAfterPassing: BlueDot,
    sourceBeforePassing: RedRhombusWithDot,
  },
  {
    becomesPassedAfter: '3',
    coordinates: [38.2437, 49.331],
    sourceAfterPassing: BlueDot,
    sourceBeforePassing: RedRhombusWithDot,
  },
  // Between 4 and 6, bottom
  {
    becomesPassedAfter: '4',
    coordinates: [38.35542326511961, 49.333980639337284],
    sourceAfterPassing: BlueEllips,
    sourceBeforePassing: RedRhombusWithEllips,
  },
  {
    becomesPassedAfter: '4',
    coordinates: [38.35254770755341, 49.307348100872275],
    sourceAfterPassing: BlueEllips,
    sourceBeforePassing: RedRhombusWithEllips,
  },
  {
    becomesPassedAfter: '5',
    coordinates: [38.38309821677876, 49.283665645779564],
    sourceAfterPassing: BlueDot,
    sourceBeforePassing: RedRhombusWithDot,
  },
  {
    becomesPassedAfter: '5',
    coordinates: [38.39777232658844, 49.31267423709917],
    sourceAfterPassing: BlueEllips,
    sourceBeforePassing: RedRhombusWithEllips,
  },
  // Between 4 and 6, top
  {
    becomesPassedAfter: '4',
    coordinates: [38.35669611800904, 49.371864469251875],
    sourceAfterPassing: BlueEllips,
    sourceBeforePassing: RedRhombusWithEllips,
  },
  {
    becomesPassedAfter: '4',
    coordinates: [38.351936251338884, 49.38773098602189],
    sourceAfterPassing: BlueCrossedEllips,
    sourceBeforePassing: RedRhombusWithCrossedEllips,
  },
  {
    becomesPassedAfter: '5',
    coordinates: [38.39843609797731, 49.38097324271499],
    sourceAfterPassing: BlueCrossedEllips,
    sourceBeforePassing: RedRhombusWithCrossedEllips,
  },
  {
    becomesPassedAfter: '5',
    coordinates: [38.390574928929254, 49.414729346453555],
    sourceAfterPassing: BlueCrossedEllips,
    sourceBeforePassing: RedRhombusWithCrossedEllips,
  },
  {
    becomesPassedAfter: '5',
    coordinates: [38.399301313657475, 49.443291687350154],
    sourceAfterPassing: BlueCrossedEllips,
    sourceBeforePassing: RedRhombusWithCrossedEllips,
  },
  // Between 6 and 9, top
  {
    becomesPassedAfter: '6',
    coordinates: [38.47432859401863, 49.365291733942115],
    sourceAfterPassing: BlueCrossedEllips,
    sourceBeforePassing: RedRhombusWithCrossedEllips,
  },
  {
    becomesPassedAfter: '7',
    coordinates: [38.50926669282512, 49.352038348525866],
    sourceAfterPassing: BlueEllipsWithDot,
    sourceBeforePassing: RedRhombusWithDot,
  },
  {
    becomesPassedAfter: '7',
    coordinates: [38.575124739304556, 49.39696587247644],
    sourceAfterPassing: BlueEllipsWithDot,
    sourceBeforePassing: RedRhombusWithEllipsAndDot,
  },
  // Between 6 and 9, bottom
  {
    becomesPassedAfter: '7',
    coordinates: [38.475332841599766, 49.290652881828436],
    sourceAfterPassing: BlueDot,
    sourceBeforePassing: RedRhombusWithDot,
  },
  {
    becomesPassedAfter: '7',
    coordinates: [38.52381966242342, 49.30370283175276],
    sourceAfterPassing: BlueDot,
    sourceBeforePassing: RedRhombusWithDot,
  },
  {
    becomesPassedAfter: '8',
    coordinates: [38.58808135857362, 49.30873115197869],
    sourceAfterPassing: BlueEllips,
    sourceBeforePassing: RedRhombusWithEllips,
  },
  // Between 9 until 12, bottom
  {
    becomesPassedAfter: '9',
    coordinates: [38.64328031099035, 49.31014240601348],
    sourceAfterPassing: BlueEllips,
    sourceBeforePassing: RedRhombusWithEllips,
  },
  {
    becomesPassedAfter: '10',
    coordinates: [38.711232664352565, 49.272358578985944],
    sourceAfterPassing: BlueDot,
    sourceBeforePassing: RedRhombusWithDot,
  },
  // Between 9 until 12, top
  {
    becomesPassedAfter: '9',
    coordinates: [38.67140935497582, 49.330866167227896],
    sourceAfterPassing: BlueEllips,
    sourceBeforePassing: RedRhombusWithEllips,
  },
  {
    becomesPassedAfter: '10',
    coordinates: [38.749234524275835, 49.343590227783636],
    sourceAfterPassing: BlueCross,
    sourceBeforePassing: RedRhombusCross,
  },
  {
    becomesPassedAfter: '10',
    coordinates: [38.77619646217761, 49.33448985291733],
    sourceAfterPassing: BlueCross,
    sourceBeforePassing: RedRhombusCross,
  },
  {
    becomesPassedAfter: '10',
    coordinates: [38.787230408546634, 49.31470287236792],
    sourceAfterPassing: BlueDot,
    sourceBeforePassing: RedRhombusWithDot,
  },
  // Right side from 12, top
  {
    becomesPassedAfter: '12',
    coordinates: [38.901225753257904, 49.32090338162371],
    sourceAfterPassing: BlueDot,
    sourceBeforePassing: RedRhombusWithDot,
  },
  {
    becomesPassedAfter: '12',
    coordinates: [38.92871992759365, 49.24923074458903],
    sourceAfterPassing: BlueEllipsWithDot,
    sourceBeforePassing: RedRhombusWithEllipsAndDot,
  },
  {
    becomesPassedAfter: '12',
    coordinates: [38.8893050277145, 49.22730914414561],
    sourceAfterPassing: BlueEllipsWithDot,
    sourceBeforePassing: RedRhombusWithEllipsAndDot,
  },
  {
    becomesPassedAfter: '12',
    coordinates: [38.92340835530547, 49.20924348065304],
    sourceAfterPassing: BlueDot,
    sourceBeforePassing: RedRhombusWithEllipsAndDot,
  },
  {
    becomesPassedAfter: '12',
    coordinates: [38.915729459721774, 49.27012040583173],
    sourceAfterPassing: BlueCross,
    sourceBeforePassing: RedRhombusCross,
  },
];

export {
  DEFAULT_MAP_CONFIG,
  GEOJSON_LINE_CONFIG_BY_TYPE,
  GEOJSON_LINE_TYPES,
  IS_TEST_MODE,
  MAP_CONTAINER_ID,
  MIN_ZOOM_FOR_MOBILE,
  NATO_MARKERS_ARRAY_WITH_CONFIG,
  SCENARIO_MARKER_TYPES,
  SCENARIO_MARKERS_ARRAY_WITH_CONFIG,
};
