export const devices = {
  desktop: 1440,
  desktopLG: 1440,
  fullHD: 1920,
  laptop: 1024,
  mobile: 430,
  smallHeight: 830,
  tabletLG: 780,
  tabletSM: 530,
};

export default Object.keys(devices).reduce((acc, device) => {
  if (device === 'desktopLG' || device === 'fullHD')
    acc[device] = `@media(min-width: ${devices[device]}px)`;
  else if (device === 'smallHeight')
    acc[device] = `@media(max-height: ${devices[device]}px)`;
  else acc[device] = `@media(max-width: ${devices[device]}px)`;
  return acc;
}, {});
