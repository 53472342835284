export const styles = {
  buttonContainer: {
    alignItems: 'center',
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    borderRadius: '4px',
    height: '56px',
    justifyContent: 'center',
    pointer: true,
    width: '56px',
  },
  buttonContainerMobile: {
    height: '48px',
    width: '48px',
  },
};
