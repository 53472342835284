/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  difficulty: {
    id: 3,
    name: 'Офіцер',
    order_number: 2,
  },
  gender: 'Чоловік',
  isOnboardingPassed: false,
  jwtAuthenticationToken: null,
  language: 'ua',
  onboardingState: 'intro',
  refreshAuthenticationToken: null,
  userId: null,
};

export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    setDifficulty: (state, { payload }) => {
      state.difficulty = payload;
    },
    setGender: (state, { payload }) => {
      state.gender = payload;
    },
    setLanguage: (state, { payload }) => {
      state.language = payload;
    },
    setOnboardingState: (state, { payload }) => {
      state.onboardingState = payload;
    },
    setOnboardingStatus: (state, { payload }) => {
      state.isOnboardingPassed = payload;
    },
    setTokens: (state, { payload }) => {
      state.jwtAuthenticationToken = payload?.token ?? null;
      state.refreshAuthenticationToken = payload?.refresh_token ?? null;
    },
    setUserId: (state, { payload }) => {
      state.userId = payload;
    },
    signOut: () => initialState,
  },
});

export const authSlicePersistedReducer = persistReducer(
  {
    key: 'authSlice',
    storage,
    whitelist: ['language'],
  },
  authSlice.reducer
);
