import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  typography,
} from 'styled-system';

const Link = styled(RouterLink)`
  ${({ fontSize, fontWeight, lineHeight, textDecoration }) => css`
    text-decoration: ${textDecoration || 'none'};
    font-weight: ${fontWeight || 600};
    font-size: ${`${fontSize}px` || '16px'};

    ${lineHeight && `line-height: ${lineHeight};`}

    ${border};
    ${color};
    ${flexbox};
    ${layout};
    ${position};
    ${space};
    ${typography};
  `}
`;

export default Link;
