import styled, { css } from 'styled-components';
import {
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  typography,
  variant,
} from 'styled-system';

const Typography = styled.p`
  ${({ gap, pointer, textDecoration, whiteSpace }) => css`
    text-decoration: ${textDecoration};
    ${whiteSpace && `white-space: ${whiteSpace};`}
    ${pointer && 'cursor: pointer;'}
    ${gap && `gap: ${gap}px;`}
    ${variant({
      variants: {
        'BN-bold-s120-lh105': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '120px',
          fontWeight: 700,
          lineHeight: '105px',
        },
        'BN-bold-s123-lh104': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '123px',
          fontWeight: 700,
          lineHeight: '104px',
        },
        'BN-bold-s140-lh125': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '140px',
          fontWeight: 700,
          lineHeight: '125px',
        },
        'BN-bold-s160-lh125': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '160px',
          fontWeight: 700,
          lineHeight: '125px',
        },
        'BN-bold-s21-lh19': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '21px',
          fontWeight: 700,
          lineHeight: '19px',
        },
        'BN-bold-s24-lh21': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '24px',
          fontWeight: 700,
          lineHeight: '21px',
        },
        'BN-bold-s28-lh25': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '28px',
          fontWeight: 700,
          lineHeight: '25px',
        },
        'BN-bold-s28-lh33': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '28px',
          fontWeight: 700,
          lineHeight: '33px',
        },
        'BN-bold-s318-lh333': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '318px',
          fontWeight: 700,
          lineHeight: '333px',
        },
        'BN-bold-s32-lh28': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '32px',
          fontWeight: 700,
          lineHeight: '28px',
        },
        'BN-bold-s35-lh35': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '35px',
          fontWeight: 700,
          lineHeight: '35px',
        },
        'BN-bold-s40-lh35': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '40px',
          fontWeight: 700,
          lineHeight: '35px',
        },
        'BN-bold-s42-lh50': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '42px',
          fontWeight: 700,
          lineHeight: '50px',
        },
        'BN-bold-s43-lh38': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '43px',
          fontWeight: 700,
          lineHeight: '38px',
        },
        'BN-bold-s44-lh44': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '44px',
          fontWeight: 700,
          lineHeight: '44px',
        },
        'BN-bold-s48-lh57': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '48px',
          fontWeight: 700,
          lineHeight: '57px',
        },
        'BN-bold-s56-lh67': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '56px',
          fontWeight: 700,
          lineHeight: '67px',
        },
        'BN-bold-s60-lh63': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '60px',
          fontWeight: 700,
          lineHeight: '63px',
        },
        'BN-bold-s64-lh64': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '64px',
          fontWeight: 700,
          lineHeight: '64px',
        },
        'BN-bold-s64-lh77': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '64px',
          fontWeight: 700,
          lineHeight: '77px',
        },
        'BN-bold-s76-lh80': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '76px',
          fontWeight: 700,
          lineHeight: '80px',
        },
        'BN-bold-s80-lh84': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '80px',
          fontWeight: 700,
          lineHeight: '84px',
        },
        'BN-bold-s88-lh75': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '88px',
          fontWeight: 700,
          lineHeight: '75px',
        },
        'BN-bold-s92-lh100': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '92px',
          fontWeight: 700,
          lineHeight: '100px',
        },
        'BN-bold-s96-lh105': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '96px',
          fontWeight: 700,
          lineHeight: '105px',
        },
        'BN-regular-s32-lh28': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '32px',
          fontWeight: 400,
          lineHeight: '28px',
        },
        'BN-regular-s36-lh43': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '36px',
          fontWeight: 400,
          lineHeight: '43px',
        },
        'BN-regular-s40-lh35': {
          fontFamily: 'BebasNeue, sans-serif',
          fontSize: '40px',
          fontWeight: 400,
          lineHeight: '35px',
        },
        'MS-bold-s14-lh22': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '14px',
          fontWeight: 700,
          lineHeight: '22px',
        },
        'MS-bold-s16-lh25': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '25px',
        },
        'MS-bold-s18-lh19': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: '19px',
        },
        'MS-bold-s18-lh22': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: '22px',
        },
        'MS-bold-s18-lh28': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '18px',
          fontWeight: 700,
          lineHeight: '28px',
        },
        'MS-bold-s20-lh21': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '21px',
        },
        'MS-bold-s24-lh30': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '24px',
          fontWeight: 700,
          lineHeight: '30px',
        },
        'MS-bold-s28-lh29': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '28px',
          fontWeight: 700,
          lineHeight: '29px',
        },
        'MS-dark-s16-lh17': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 800,
          lineHeight: '17px',
        },
        'MS-regular-s13-lh16': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '13px',
          fontWeight: 500,
          lineHeight: '16px',
        },
        'MS-regular-s14-lh17': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '17px',
        },
        'MS-regular-s14-lh22': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '22px',
        },
        'MS-regular-s16-lh25': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '25px',
        },
        'MS-regular-s18-lh22': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '22px',
        },
        'MS-regular-s18-lh28': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '28px',
        },
        'MS-regular-s20-lh31': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '20px',
          fontWeight: 500,
          lineHeight: '31px',
        },
        'MS-regular-s22-lh34': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '22px',
          fontWeight: 500,
          lineHeight: '34px',
        },
        'MS-regular-s24-lh24': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '24px',
          fontWeight: 500,
          lineHeight: '24px',
        },
        'MS-regular-s24-lh28': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '24px',
          fontWeight: 500,
          lineHeight: '28px',
        },
        'MS-regular-s24-lh37': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '24px',
          fontWeight: 500,
          lineHeight: '37px',
        },
        'MS-regular-s26-lh28': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '26px',
          fontWeight: 500,
          lineHeight: '28px',
        },
        'MS-regular-s26-lh32': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '26px',
          fontWeight: 500,
          lineHeight: '32px',
        },
        'MS-regular-s26-lh40': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '26px',
          fontWeight: 500,
          lineHeight: '40px',
        },
        'MS-regular-s30-lh36': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '30px',
          fontWeight: 500,
          lineHeight: '36px',
        },
        'MS-regular-s32-lh50': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '32px',
          fontWeight: 500,
          lineHeight: '50px',
        },
        'MS-semibold-s14-lh15': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '14px',
          fontWeight: 600,
          lineHeight: '15px',
        },
        'MS-semibold-s16-lh17': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '17px',
        },
        'MS-semibold-s16-lh25': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '25px',
        },
        'MS-semibold-s16-lh32': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '32px',
        },
        'MS-semibold-s18-lh19': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '19px',
        },
        'MS-semibold-s18-lh27': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '27px',
        },
        'MS-semibold-s20-lh30': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '30px',
        },
        'MS-semibold-s22-lh34': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '22px',
          fontWeight: 600,
          lineHeight: '34px',
        },
        'MS-semibold-s24-lh30': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '30px',
        },
        'MS-semibold-s24-lh36': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '24px',
          fontWeight: 600,
          lineHeight: '36px',
        },
        'MS-semibold-s27-lh32': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '27px',
          fontWeight: 600,
          lineHeight: '32px',
        },
        'MS-semibold-s32-lh50': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '32px',
          fontWeight: 600,
          lineHeight: '50px',
        },
        'MS-semiregular-s14-lh22': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22px',
        },
        'MS-semiregular-s16-lh21': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '21px',
        },
        'MS-semiregular-s18-lh22': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '18px',
          fontWeight: 400,
          lineHeight: '22px',
        },
        'MS-semiregular-s20-lh22': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '20px',
          fontWeight: 400,
          lineHeight: '22px',
        },
        'MS-semiregular-s22-lh34': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '22px',
          fontWeight: 400,
          lineHeight: '34px',
        },
        'MS-semiregular-s24-lh30': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '24px',
          fontWeight: 400,
          lineHeight: '30px',
        },
        'MS-semiregular-s28-lh29': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '28px',
          fontWeight: 400,
          lineHeight: '29px',
        },
        'MS-thin-s16-lh25': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '16px',
          fontWeight: 300,
          lineHeight: '25px',
        },
        'MS-thin-s24-lh37': {
          fontFamily: 'Montserrat, sans-serif',
          fontSize: '24px',
          fontWeight: 300,
          lineHeight: '37px',
        },
      },
    })}

    ${border};
    ${color};
    ${flexbox};
    ${layout};
    ${position};
    ${space};
    ${typography};
  `}
`;

export default Typography;
