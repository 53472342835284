import { ControlButtons, SquareButton } from '@components';
import { Flex } from '@styles';
import { arrayOf, object } from 'prop-types';
import React, { useState } from 'react';
import { ReactComponent as CrossIcon } from 'src/assets/svgs/close.svg';
import { ReactComponent as MenuBurgerIcon } from 'src/assets/svgs/menu-burger.svg';

import { getControlsContainerStyles } from './ControlsDropdown.styles';

const ControlsDropdown = ({ buttonContainerProps, controls }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Flex position="relative">
      <SquareButton
        containerProps={buttonContainerProps}
        onClick={handleMenuClick}
        SVG={isOpen ? CrossIcon : MenuBurgerIcon}
      />
      <ControlButtons
        buttonContainerProps={buttonContainerProps}
        containerProps={getControlsContainerStyles({ isOpen })}
        controls={controls}
        direction="column"
      />
    </Flex>
  );
};

ControlsDropdown.defaultProps = {
  buttonContainerProps: {},
};

ControlsDropdown.propTypes = {
  buttonContainerProps: object,
  controls: arrayOf(['exit', 'sound', 'language', 'info']).isRequired,
};

export default ControlsDropdown;
