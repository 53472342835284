import styled, { css } from 'styled-components';
import { border, color, flexbox, layout, position, space } from 'styled-system';

const Image = styled.img`
  ${({ mixBlendMode }) => css`
    ${mixBlendMode && `mix-blend-mode: ${mixBlendMode};`}

    ${border};
    ${color};
    ${flexbox};
    ${layout};
    ${position};
    ${space};
  `}
`;

export default Image;
