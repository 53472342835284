import { useScreenSize } from '@hooks';
import { Button, Flex, Modal as ModalContainer, Typography } from '@styles';
import { ReactComponent as CloseIcon } from '@svgs/close.svg';
import {
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { Icon } from '../Icon';
import { Line } from '../Line';

const Modal = ({
  alignItems,
  children,
  firstButtonProps = {},
  firstButtonText,
  innerContainerProps = {},
  isVisible,
  justifyContent,
  modalContainerProps = {},
  modalMaxHeight,
  modalMaxWidth,
  onClose,
  onFirstButtonClick,
  onSecondButtonClick,
  secondButtonProps = {},
  secondButtonText,
  setModalVisible,
  withBottomButtons,
  withCloseIcon,
}) => {
  const dialogRef = useRef();
  const { isMobile, isTabletSM } = useScreenSize();

  const showModal = () => {
    document.body.style.overflow = 'hidden';
    dialogRef.current.showModal();
  };

  const closeModal = () => {
    document.body.style.overflow = 'unset';
    dialogRef.current.close();
    setModalVisible?.(false);
  };

  useEffect(() => {
    if (isVisible) {
      showModal();
    } else {
      closeModal();
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isVisible]);

  const handleMouseDown = (event) => {
    if (event.target === event.currentTarget) {
      event.currentTarget.close();
      setModalVisible?.(false);
    }
  };

  const handleCloseButtonClick = () => {
    closeModal();
    if (onClose) onClose();
  };

  const handleFirstButtonClick = () => {
    onFirstButtonClick();
    closeModal();
  };

  const handleSecondButtonClick = () => {
    onSecondButtonClick();
    closeModal();
  };

  return (
    <ModalContainer
      ref={dialogRef}
      onMouseDown={handleMouseDown}
      withBackdropNoise
      {...modalContainerProps}
    >
      <Flex
        maxHeight={modalMaxHeight}
        maxWidth={modalMaxWidth}
        minHeight={450}
        position="relative"
        width="100%"
        {...(withBottomButtons && {
          flexDirection: 'column',
        })}
        {...(justifyContent && {
          justifyContent,
        })}
        {...(alignItems && {
          alignItems,
        })}
        {...(withBottomButtons &&
          isTabletSM && {
            justifyContent: 'space-between',
          })}
        {...innerContainerProps}
      >
        {withCloseIcon && (
          <Flex position="absolute" right={2} top={2} zIndex={10}>
            <Button
              height={24}
              onClick={handleCloseButtonClick}
              variant="textButton"
            >
              <Icon SVG={CloseIcon} />
            </Button>
          </Flex>
        )}
        {children}
        {withBottomButtons && (
          <Flex flexDirection="column" pb={40} px={isMobile ? 16 : 40}>
            <Line mb={4} />
            <Flex
              flexDirection={isMobile ? 'column' : 'row'}
              gap={isMobile ? 10 : 0}
              justifyContent={secondButtonText ? 'space-between' : 'center'}
            >
              <Button {...firstButtonProps} onClick={handleFirstButtonClick}>
                <Typography variant="MS-bold-s16-lh25">
                  {firstButtonText}
                </Typography>
              </Button>
              {secondButtonText && (
                <Button
                  {...secondButtonProps}
                  onClick={handleSecondButtonClick}
                >
                  <Typography variant="MS-bold-s16-lh25">
                    {secondButtonText}
                  </Typography>
                </Button>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
    </ModalContainer>
  );
};

Modal.defaultProps = {
  alignItems: string,
  firstButtonProps: null,
  firstButtonText: null,
  innerContainerProps: null,
  justifyContent: string,
  modalContainerProps: null,
  modalMaxHeight: 618,
  modalMaxWidth: 880,
  onClose: null,
  onFirstButtonClick: () => {},
  onSecondButtonClick: () => {},
  secondButtonProps: null,
  secondButtonText: null,
  withBottomButtons: false,
  withCloseIcon: true,
};

Modal.propTypes = {
  alignItems: null,
  children: node.isRequired,
  firstButtonProps: object,
  firstButtonText: string,
  innerContainerProps: object,
  isVisible: bool.isRequired,
  justifyContent: null,
  modalContainerProps: object,
  modalMaxHeight: oneOfType([number, string]),
  modalMaxWidth: oneOfType([number, string]),
  onClose: func,
  onFirstButtonClick: func,
  onSecondButtonClick: func,
  secondButtonProps: object,
  secondButtonText: string,
  setModalVisible: func.isRequired,
  withBottomButtons: bool,
  withCloseIcon: bool,
};

export default Modal;
