import styled, { css } from 'styled-components';
import { color, flexbox, layout, space, typography } from 'styled-system';

const TextInput = styled.input`
  ${({ backgroundColor, theme: { colors } }) => css`
    padding: 0px;
    outline-color: transparent;
    border: none;

    color: ${colors.white[100]};
    background-color: ${backgroundColor
      ? `${backgroundColor} important!`
      : 'transparent !important'};
    user-select: none;

    line-height: 25px;
    font-size: 16px;

    ${color}
    ${flexbox};
    ${layout};
    ${space};
    ${typography};
  `}
`;

export default TextInput;
