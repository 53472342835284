import { withFlexProps } from '@hocs';
import { theme } from '@styles';
import styled, { css } from 'styled-components';
import { border, color, flexbox, layout, position, space } from 'styled-system';

const WideGradient = styled.div`
  ${({ customColor }) => css`
    display: flex;
    flex-shrink: 0;
    width: 1440px;
    height: 1583px;
    border-radius: 1583px;
    pointer-events: none;
    background:
      linear-gradient(
          to bottom right,
          ${customColor || theme.colors.vividYellow[100]} 0%,
          rgba(255, 245, 4, 0) 50%
        )
        bottom right / 50% 50% no-repeat,
      linear-gradient(
          to bottom left,
          ${customColor || theme.colors.vividYellow[100]} 0%,
          rgba(255, 245, 4, 0) 50%
        )
        bottom left / 50% 50% no-repeat,
      linear-gradient(
          to top left,
          ${customColor || theme.colors.vividYellow[100]} 0%,
          rgba(255, 245, 4, 0) 50%
        )
        top left / 50% 50% no-repeat,
      linear-gradient(
          to top right,
          ${customColor || theme.colors.vividYellow[100]} 0%,
          rgba(255, 245, 4, 0) 50%
        )
        top right / 50% 50% no-repeat;
    mix-blend-mode: color;
    filter: blur(0px);

    ${border};
    ${color};
    ${flexbox};
    ${layout};
    ${position};
    ${space};
  `}
`;

export default withFlexProps(WideGradient);
