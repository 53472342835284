import { useScreenSize } from '@hooks';
import { Flex, theme } from '@styles';
import { arrayOf, bool, func, number, object } from 'prop-types';
import React from 'react';

const NOOP = () => {};

const Stepper = ({
  containerProps,
  currentStep,
  isDisabled,
  onStepChange,
  size,
  steps,
}) => {
  const { isLaptop } = useScreenSize();
  const dotSize = size || (isLaptop ? 16 : 22);

  const onClick = (index) => () => {
    onStepChange(index);
  };

  return (
    <Flex gap={8} opacity={0.6} {...containerProps}>
      {steps?.map((step, index) => (
        <Flex
          key={index}
          bg={
            index === currentStep
              ? theme.colors.vividYellow[70]
              : theme.colors.alto[30]
          }
          borderRadius="50%"
          height={dotSize}
          onClick={isDisabled ? NOOP : onClick(index)}
          opacity={index === currentStep ? 1 : 0.2}
          pointer={!isDisabled}
          width={dotSize}
        />
      ))}
    </Flex>
  );
};

Stepper.defaultProps = {
  containerProps: {
    justifyContent: 'flex-end',
  },
  isDisabled: false,
  size: null,
};

Stepper.propTypes = {
  containerProps: object,
  currentStep: number.isRequired,
  isDisabled: bool,
  onStepChange: func.isRequired,
  size: number,
  steps: arrayOf(number).isRequired,
};

export default Stepper;
