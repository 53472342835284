import { media } from '@utils';
import { createGlobalStyle, css } from 'styled-components';

import BebasNeueTTF from '../fonts/BebasNeue-Regular.ttf';
import MontserratTTF from '../fonts/Montserrat-VariableFont_wght.ttf';
import theme from './theme';

const { desktop, laptop, mobile } = media;

export const fontFace = (family, url) => css`
  @font-face {
    font-family: '${family}';
    src: url('${url}') format('truetype');
    font-display: swap;
  }
`;
const ResetStyle = createGlobalStyle`
  ${fontFace('Montserrat', MontserratTTF)};
  ${fontFace('BebasNeue', BebasNeueTTF)};
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ul[class],
  ol[class] {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class],
  ol[class],
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
  }

  body {
    background-color: #212121;
    margin:0;
    padding:0;
    min-height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
  }

  p {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;
  }

  ul[class],
  ol[class] {
    list-style: none;
  }

  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  img {
    max-width: 100%;
    display: block;
  }

  article > * + * {
    margin-top: 1em;
  }

  input,
  button,
  textarea,
  select {
    font-family: 'Montserrat', sans-serif;
  }

  div {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  textarea:focus, input:focus{
    outline: none;
  }

  .carousel-card-class {
    ${desktop} {
      height: 830px;
      width: 400px !important;
      max-width: 400px !important;
      margin-right: 2px;
    }
    ${laptop} {
      height: 709px;
      width: 343px !important;
      max-width: 343px !important;
      margin-right: 2px;
    }
  }

  .ambassador-card-class {
    ${desktop} {
      height: 602px;
      width: 400px !important;
      max-width: 400px !important;
      margin-right: 4px;
    }
    ${laptop} {
      height: 602px;
      width: 343px !important;
      max-width: 343px !important;
      margin-right: 4px;
    }
  }

  .carousel-container { 
    width: calc(100% - 156px);
    ${desktop} {
      width: calc(100% - 156px);
    }
    ${laptop} {
      width: calc(100% - 24px);
    }
    ${mobile} {
      width: calc(100% - 16px);
    }
  }

  .inner-carousel-container {
    ${mobile} {
      overflow: visible;
    }
  }
  
  ul {
    margin: 0;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  /* ${mobile} {
    ul {
      padding-left: 0;
    }
  } */

  li::marker {
    color: white;
  }

  li.marker-60::marker {
    color: rgba(255,255,255,0.6);
  }

  .scrollbar-hidden {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  .scrollbar-hidden::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
  }

  input[type='radio'] {
    appearance: none;
    border: 1px solid ${theme.colors.schoolBusYellow[100]};
    width: 18px;
    height: 18px;
    border-radius: 100px;
  }

  input:checked {
    border: 9px solid ${theme.colors.schoolBusYellow[100]};
  }

  .slider {
    height:  3px;
    width: 100%;
    background-color: grey;
  }

  .thumb {
    width: 15px;
    transform: translate(0, -45%);
    height: 15px;
    border-radius: 50%;
    background-color: white;
    outline: none;
  }
  .track-0 {
    height:  3px;
    background-color: white;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
      transition: background-color 5000s ease-in-out 0s;
      box-shadow: inset 0 0 20px 20px #23232329;
      -webkit-box-shadow: none;
  }

  .blink {
    animation: blink-animation 1.5s infinite;
  }
  
  @keyframes blink-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export default ResetStyle;
