export const findSkippedStepIndex = ({ finishedStepIndices, stepsAmount }) => {
  let index = 0;

  while (finishedStepIndices.includes(index)) {
    if (index === stepsAmount) return false;

    index += 1;
  }

  return index;
};
