import { ControlButtons } from '@components';
import { useScreenSize } from '@hooks';
import Main from '@pngs/main2.png';
import { Button, Flex, Image, theme, Typography } from '@styles';
import { bool, func, node, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { EllipseGradient } from '../EllipseGradient';
import { FadeInOut } from '../FadeInOut';
import { authTemplateLayoutByVariant } from './AuthTemplate.layoutProps';

const AuthTemplate = ({
  buttonDisabled,
  buttonText,
  formComponent,
  onMainButtonClick,
  onSubtitleButtonClick,
  subButtonText,
  titleText,
}) => {
  const { i18n } = useTranslation();
  const { device, isTabletSM } = useScreenSize();
  const layout = authTemplateLayoutByVariant[device || 'desktop'];

  const handleSubtitleTextButtonPress = () => {
    onSubtitleButtonClick();
  };

  const handleButtonClick = () => {
    onMainButtonClick();
  };

  return (
    <Flex
      alignItems="flex-end"
      backgroundColor={theme.colors.white[5]}
      flexDirection="column"
      justifyContent="flex-start"
      minWidth={isTabletSM ? '100%' : '604px'}
      position="relative"
      width={isTabletSM ? '100%' : '40%'}
    >
      <ControlButtons
        containerProps={{
          margin: '8px 8px 0 0',
        }}
        controls={['language']}
      />
      <Flex
        alignItems="center"
        height="100%"
        justifyContent="center"
        width="100%"
        {...(isTabletSM
          ? { borderRadius: 24, gap: 2, mt: 30, px: 16 }
          : { borderRadius: 4, gap: 40 })}
      >
        <FadeInOut
          duration={100}
          show
          style={{ width: isTabletSM ? 'auto' : '400px' }}
        >
          <Flex
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            width="100%"
          >
            <Typography
              color={theme.colors.white[70]}
              variant={layout.titleVariant}
            >
              {titleText}
            </Typography>
            <Typography
              color={theme.colors.white[100]}
              variant={layout.subtitleVariant}
            >
              {i18n.t('Auth.SignIn.Subtitle')}
            </Typography>
          </Flex>
          {formComponent}
          <Flex flexDirection="column">
            <Button
              disabled={buttonDisabled}
              onClick={handleButtonClick}
              variant="primary"
            >
              <Typography
                color={buttonDisabled ? '#6D6D6D' : theme.colors.black[100]}
                variant="MS-bold-s16-lh25"
              >
                {buttonText}
              </Typography>
            </Button>
            <Button
              onClick={handleSubtitleTextButtonPress}
              variant="textButton"
            >
              <Typography
                color={theme.colors.white[100]}
                mt={3}
                variant="MS-semiregular-s14-lh22"
              >
                {subButtonText}
              </Typography>
            </Button>
          </Flex>
          {isTabletSM && (
            <Flex mt={64} position="relative">
              <EllipseGradient
                bottom={-40}
                left={-30}
                position="absolute"
                width={177}
              />
              <Image maxWidth="100%" mixBlendMode="overlay" src={Main} />
            </Flex>
          )}
        </FadeInOut>
      </Flex>
    </Flex>
  );
};

AuthTemplate.defaultProps = {
  onSubtitleButtonClick: () => {},
  subButtonText: null,
};

AuthTemplate.propTypes = {
  buttonDisabled: bool.isRequired,
  buttonText: string.isRequired,
  formComponent: node.isRequired,
  onMainButtonClick: func.isRequired,
  onSubtitleButtonClick: func,
  subButtonText: string,
  titleText: string.isRequired,
};

export default AuthTemplate;
