export const stepCardLayoutByVariant = {
  desktop: {
    pb: 64,
    pl: 104,
    pr: 104,
    pt: 64,
  },
  laptop: {
    pb: 24,
    pl: 64,
    pr: 64,
    pt: 24,
  },
  mobile: {
    pb: 16,
    pl: 16,
    pr: 16,
    pt: 16,
  },
  tablet: {
    pb: 16,
    pl: 16,
    pr: 16,
    pt: 16,
  },
};
