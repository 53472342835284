/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import {
  ControlButtons,
  ControlsDropdown,
  DragPhotoBlock,
  FadeInOut,
  SquareButton,
  Stepper,
} from '@components';
import { useScreenSize } from '@hooks';
import NoiseImage from '@pngs/Noise25.png';
import { scenarioApi } from '@store';
import { Button, Flex, theme, Typography } from '@styles';
import { ReactComponent as EyeIcon } from '@svgs/eye.svg';
import { ReactComponent as EyeCrossIcon } from '@svgs/eyeCross.svg';
import { any, arrayOf, func, number, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';
import { capitalizeFirstLetter } from 'src/helpers';

import CorrectOverlay from './CorrectOverlay';
import IncorrectOverlay from './IncorrectOverlay';
import { stepCardLayoutByVariant } from './StepCard.layoutProps';
import { styles } from './StepCard.styles';

// eslint-disable-next-line react/prop-types
const ImageButtonTooltip = ({ id, isHidden, text }) => {
  if (isHidden) {
    return null;
  }

  const defaultTooltipStyle = {
    color: 'white',
    fontFamily: 'Montserrat',
    fontSize: '16px',
  };

  const defaultTooltipProps = {
    anchorSelect: `#${id}`,
    place: 'left',
  };

  const backgroundColor =
    id === 'show-image-btn'
      ? 'rgba(56, 56, 56, 255)'
      : 'rgba(255, 255, 255, 0.15)';

  return (
    <Tooltip
      {...defaultTooltipProps}
      style={{
        backgroundColor,
        ...defaultTooltipStyle,
      }}
    >
      {text}
    </Tooltip>
  );
};

const scrollContainerWithContent = ({
  containerId = 'scrollableImgContainer',
  contentId = 'scrollableImg',
  scrollInterval = 10,
  scrollIterations = 30,
} = {}) => {
  const scrollableContainer = document.getElementById(containerId);
  const scrollableContent = document.getElementById(contentId);

  // Content width is higher that container's because of overflow-x: scroll
  let widthToScrollPast =
    (scrollableContent.offsetWidth - scrollableContainer.offsetWidth) / 4;

  const widthToScrollInOneIteration = widthToScrollPast / scrollIterations;

  const interval = setInterval(() => {
    if (widthToScrollPast > 0) {
      scrollableContainer.scrollLeft += widthToScrollInOneIteration;
      widthToScrollPast -= widthToScrollInOneIteration;
    } else {
      clearInterval(interval);
    }
  }, scrollInterval);
};

const StepCard = ({
  backgroundImage,
  currentStep,
  onContinue,
  onStepChange,
  onStepFinish,
  steps,
}) => {
  const { i18n } = useTranslation();
  const { device, isDesktop, isTabletSM } = useScreenSize();
  const layout = stepCardLayoutByVariant[device || 'desktop'];
  const step = steps?.[currentStep];

  const [isContentShown, setIsContentShown] = useState(false);
  const [validatedAnswer, setValidatedAnswer] = useState(null);
  const [wasImageScrolled, setWasImageScrolled] = useState(false);
  const [wasContentHidden, setWasContentHidden] = useState(false);
  const [validateAnswer] = scenarioApi.useValidateStepByIdMutation();
  const [isDragPhotoBlockShown, setIsDragPhotoBlockShown] = useState(false);

  const [isShowImageBtnHovered, setIsShowImageBtnHovered] = useState(false);

  useEffect(() => {
    if (!wasContentHidden) {
      setIsDragPhotoBlockShown(true);

      if (!wasImageScrolled) {
        setWasImageScrolled(true);
        setTimeout(() => {
          scrollContainerWithContent();
        }, 1000);
      }

      setTimeout(() => {
        setIsContentShown(true);
        setIsDragPhotoBlockShown(false);
      }, 5000);
    }
  }, []);

  useEffect(() => {
    if (isContentShown) {
      setIsDragPhotoBlockShown(false);
    }
  }, [isContentShown]);

  const handleDragPhotoBlockClose = () => {
    const listener = () => {
      setIsDragPhotoBlockShown(false);
      window.removeEventListener('touchmove', listener);
    };

    window.addEventListener('touchmove', listener);
  };

  const showQuestion = () => {
    setWasContentHidden(false);
    setIsContentShown(true);
  };

  const hideQuestion = () => {
    setIsDragPhotoBlockShown(true);
    setWasContentHidden(true);
    setIsContentShown(false);
    handleDragPhotoBlockClose();
  };

  const handleValidateAnswer = (optionId) => async () => {
    const { data } = await validateAnswer({ data: { optionId }, id: step?.id });
    setValidatedAnswer(data?.data);
    onStepFinish();
  };

  const handleContinue = () => {
    setValidatedAnswer(null);
    onContinue();
  };

  const renderOverlay = () => {
    if (validatedAnswer?.is_correct === undefined) return null;

    return validatedAnswer?.is_correct ? (
      <CorrectOverlay
        currentStep={currentStep}
        onContinue={handleContinue}
        onStepChange={onStepChange}
        steps={steps}
        validatedAnswer={validatedAnswer}
      />
    ) : (
      <IncorrectOverlay
        currentStep={currentStep}
        onContinue={handleContinue}
        onStepChange={onStepChange}
        steps={steps}
        validatedAnswer={validatedAnswer}
      />
    );
  };

  return (
    <Flex
      alignItems="center"
      height="100%"
      justifyContent="center"
      width="100%"
      {...(isTabletSM && { borderRadius: 4 })}
    >
      <Flex
        id="scrollableImgContainer"
        style={{
          height: 'calc(100% - 32px)',
          overflowX: 'scroll',
          position: 'absolute',
          width: 'calc(100% - 32px)',
          ...(isTabletSM && { borderRadius: 4 }),
        }}
      >
        <img
          alt=""
          draggable={false}
          height="100%"
          id="scrollableImg"
          src={backgroundImage}
          style={{
            display: 'inline',
            maxWidth: 'none',
            objectFit: 'cover',
            zIndex: 2,
          }}
          width={isTabletSM ? 'auto' : '100%'}
        />
      </Flex>

      <FadeInOut
        duration={300}
        show={isDragPhotoBlockShown && isTabletSM}
        style={{ height: '100%', overflowY: 'auto', width: '100%' }}
      >
        <DragPhotoBlock
          position="absolute"
          top="40%"
          width="calc(100% - 32px)"
          zIndex={isDragPhotoBlockShown ? 4 : -1}
        />
      </FadeInOut>

      <FadeInOut
        duration={500}
        show={!isContentShown}
        style={{
          pointerEvents: isContentShown ? 'none' : 'all',
          position: 'absolute',
          right: layout.pl + (isTabletSM ? 0 : 22),
          top: layout.pt + (isTabletSM ? 7 : 24),
          zIndex: 3,
        }}
      >
        <SquareButton
          containerProps={{
            backdropFilter: 'none',
            backgroundColor: 'rgba(255, 255, 255, 0.15)',
            id: 'hide-image-btn',
          }}
          onClick={showQuestion}
          SVG={EyeIcon}
        />
        <ImageButtonTooltip
          id="hide-image-btn"
          isHidden={!isDesktop}
          text={i18n.t('Scenario.Controls.HideImage')}
        />
      </FadeInOut>
      <FadeInOut
        duration={500}
        show={isContentShown}
        style={{
          height: '100%',
          pointerEvents: isContentShown ? 'all' : 'none',
          position: 'absolute',
          width: '100%',
          zIndex: 2,
          ...(isTabletSM && { borderRadius: 4 }),
        }}
      >
        <Flex
          backgroundBlendMode="overlay"
          backgroundColor={theme.colors.black[70]}
          backgroundImage={NoiseImage}
          backgroundSize="100%"
          height="100%"
          position="absolute"
          width="100%"
          zIndex={5}
          {...(isTabletSM && { borderRadius: 4 })}
          {...layout}
        >
          {renderOverlay()}
          {!validatedAnswer && (
            <FadeInOut
              duration={300}
              show={!validatedAnswer}
              style={{ height: '100%', overflowY: 'auto', width: '100%' }}
            >
              <Flex
                flexDirection="column"
                gap={12}
                height="100%"
                justifyContent="space-between"
                p={isTabletSM ? 0 : 24}
                width="100%"
              >
                <Flex alignItems="center" justifyContent="space-between">
                  {isTabletSM ? (
                    <ControlsDropdown
                      buttonContainerProps={styles.controlButton}
                      controls={['language', 'sound', 'exit']}
                    />
                  ) : (
                    <ControlButtons
                      buttonContainerProps={styles.controlButton}
                      controls={['exit', 'sound', 'language']}
                    />
                  )}

                  <Flex alignItems="center" gap={16}>
                    {isShowImageBtnHovered ? null : (
                      <Stepper
                        currentStep={currentStep}
                        onStepChange={onStepChange}
                        steps={steps}
                      />
                    )}
                    {isContentShown ? (
                      <SquareButton
                        containerProps={{
                          ...styles.controlButton,
                          id: 'show-image-btn',
                        }}
                        onClick={hideQuestion}
                        onMouseEnter={() => setIsShowImageBtnHovered(true)}
                        onMouseLeave={() => setIsShowImageBtnHovered(false)}
                        SVG={isContentShown ? EyeIcon : EyeCrossIcon}
                      />
                    ) : null}
                    <ImageButtonTooltip
                      id="show-image-btn"
                      isHidden={!isDesktop}
                      text={i18n.t('Scenario.Controls.ShowImage')}
                    />
                  </Flex>
                </Flex>
                <Flex flexDirection="column" gap={24} mt={isTabletSM ? 10 : 0}>
                  <Typography
                    color={theme.colors.white[100]}
                    px={isTabletSM ? '10px' : 0}
                    variant={
                      isTabletSM ? 'MS-regular-s14-lh17' : 'MS-regular-s24-lh28'
                    }
                  >
                    {step?.description}
                  </Typography>
                  <Typography
                    color={theme.colors.white[100]}
                    px={isTabletSM ? '10px' : 0}
                    variant={
                      isTabletSM ? 'MS-regular-s14-lh17' : 'MS-regular-s24-lh28'
                    }
                  >
                    {step?.question}
                  </Typography>
                </Flex>
                <div>
                  <Flex
                    flexDirection={
                      isTabletSM || step?.options?.length > 2 ? 'column' : 'row'
                    }
                    gap={isTabletSM ? 8 : 16}
                    height="100%"
                    justifyContent="space-between"
                  >
                    {step?.options?.map((option) => (
                      <Flex
                        key={option.id}
                        backgroundColor={theme.colors.white[10]}
                        borderRadius="4px"
                        flex={
                          step?.options?.length > 2
                            ? 1
                            : 1 / (step?.options.length || 1)
                        }
                        {...(!isTabletSM && {
                          minHeight: step?.options?.length > 2 ? 80 : 100,
                        })}
                      >
                        <Button
                          height="100%"
                          onClick={handleValidateAnswer(option.id)}
                          px={isTabletSM ? '8px' : 40}
                          py={16}
                          variant="textButton"
                          width="100%"
                        >
                          <Typography
                            color={theme.colors.white[100]}
                            textAlign={
                              step?.options?.length <= 2 ? 'center' : 'left'
                            }
                            variant={
                              isTabletSM
                                ? step?.options?.length > 2
                                  ? 'MS-regular-s13-lh16'
                                  : 'MS-semibold-s16-lh25'
                                : step?.options?.length > 2
                                  ? 'MS-regular-s24-lh24'
                                  : 'MS-regular-s24-lh24'
                            }
                            width="100%"
                          >
                            {capitalizeFirstLetter(option.statement)}
                          </Typography>
                        </Button>
                      </Flex>
                    ))}
                  </Flex>
                </div>
              </Flex>
            </FadeInOut>
          )}
        </Flex>
      </FadeInOut>
    </Flex>
  );
};

StepCard.propTypes = {
  backgroundImage: string,
  currentStep: number.isRequired,
  onContinue: func.isRequired,
  onFinish: func.isRequired,
  onStepChange: func.isRequired,
  onStepFinish: func.isRequired,
  steps: arrayOf(any),
};

StepCard.defaultProps = {
  backgroundImage: null,
  steps: [],
};

export default StepCard;
