import { api } from '../api';

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    changeAvatar: build.mutation({
      invalidatesTags: ['user'],
      query: ({ avatar, username }) => ({
        body: {
          avatar,
          username,
        },
        method: 'PATCH',
        url: 'user/info',
      }),
    }),
    changePassword: build.mutation({
      invalidatesTags: ['user'],
      query: ({ newPassword, oldPassword }) => ({
        body: {
          newPassword,
          oldPassword,
        },
        method: 'PUT',
        url: 'user/change-password',
      }),
    }),
    deleteAccount: build.mutation({
      invalidatesTags: ['user'],
      query: () => ({
        method: 'DELETE',
        url: 'user',
      }),
    }),
    getCourseProgressById: build.query({
      query: ({ id }) => ({
        url: `user/progress/${id}`,
      }),
    }),
    getUser: build.query({
      providesTags: ['user'],
      query: () => ({
        url: 'user/me',
      }),
    }),
    updateOnboardingProgress: build.mutation({
      query: (data) => ({
        body: data,
        method: 'POST',
        url: 'user/progress/onboarding',
      }),
    }),
    updateOneTestProgress: build.mutation({
      query: (data) => ({
        body: data,
        method: 'PATCH',
        url: 'user/test-progress',
      }),
    }),
    updateTestProgress: build.mutation({
      query: (data) => ({
        body: data,
        method: 'POST',
        url: 'user/test-progress',
      }),
    }),
    updateUserInfo: build.mutation({
      query: (data) => ({
        body: data,
        method: 'PATCH',
        url: 'user/info',
      }),
    }),
    updateUserProgress: build.mutation({
      query: (data) => ({
        body: data,
        method: 'POST',
        url: 'user/progress',
      }),
    }),
  }),
  reducerPath: 'userApi',
});
