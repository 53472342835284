import { Input } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useScreenSize } from '@hooks';
import { authApi, authSlice } from '@store';
import { Flex, theme, Typography } from '@styles';
import { schemas } from '@utils';
import { func } from 'prop-types';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getErrorNotificationText } from 'src/helpers/error';

import AuthTemplate from './AuthTemplate';

const SignInForm = ({ onSignInButtonClick, setAuthModalState }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { isTabletSM } = useScreenSize();

  const [signIn, { isLoading: isSignInMutationLoading }] =
    authApi.useSignInMutation();

  const { clearErrors, control, formState, handleSubmit, reset, setError } =
    useForm({
      defaultValues: {
        password: '',
        username: '',
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(schemas.signInForm),
    });

  const handleLogInButtonClick = () => {
    handleSubmit(async (formData) => {
      try {
        clearErrors('server');

        const { data } = await signIn({
          password: formData.password,
          username: formData.username,
        }).unwrap();

        dispatch(authSlice.actions.setTokens(data));
        dispatch(authSlice.actions.setUserId(data?.id));

        reset();

        onSignInButtonClick(!!data?.gender);
      } catch ({ data }) {
        setError('server', {
          message: data.message,
          type: 'server',
        });
      }
    })();
  };

  const handleSignUpButtonClick = () => {
    setAuthModalState('signUp');
  };

  const errorNotificationText = getErrorNotificationText(formState.errors);
  const isErrorNotificationVisible = !!errorNotificationText;

  const errorText = !formState.errors.server
    ? i18n.t(errorNotificationText)
    : i18n.t(errorNotificationText) || errorNotificationText;
  return (
    <FormProvider control={control} formState={formState}>
      <AuthTemplate
        buttonDisabled={!formState.isValid || isSignInMutationLoading}
        buttonText={i18n.t('Auth.SignIn.Button')}
        formComponent={
          <Flex
            flexDirection="column"
            mb={isTabletSM ? 30 : 0}
            mt={isTabletSM ? 30 : 20}
          >
            <Input mb={3} name="username" type="text" variant="username" />
            <Input mb={1} name="password" type="password" variant="password" />
            <Typography
              color={theme.colors.red[100]}
              height={25}
              mb={2}
              style={{ textAlign: 'center' }}
              variant="MS-regular-s16-lh25"
            >
              {isErrorNotificationVisible ? errorText : ' '}
            </Typography>
          </Flex>
        }
        onMainButtonClick={handleLogInButtonClick}
        onSubtitleButtonClick={handleSignUpButtonClick}
        subButtonText={i18n.t('Auth.SignIn.SubButton')}
        titleText={i18n.t('Auth.SignIn.Title')}
      />
    </FormProvider>
  );
};

SignInForm.propTypes = {
  onSignInButtonClick: func.isRequired,
  setAuthModalState: func.isRequired,
};

export default SignInForm;
