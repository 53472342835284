import { useScreenSize } from '@hooks';
import { Button, Flex, Typography } from '@styles';
import { func, number } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LevelsBar from './LevelsBar';
import { styles } from './LevelsIndicator.styles';

const LevelsIndicator = ({
  allLevelsAmount,
  completedLevelsAmount,
  onPlayButtonClick,
}) => {
  const { isTabletLG } = useScreenSize();
  const { t } = useTranslation();

  return (
    <Flex {...styles.container} {...(isTabletLG && styles.containerMobile)}>
      <LevelsBar
        allLevelsAmount={allLevelsAmount}
        completedLevelsAmount={completedLevelsAmount}
      />
      <Button
        variant="primary"
        {...styles.playButton}
        {...(isTabletLG && styles.playButtonMobile)}
        onClick={onPlayButtonClick}
      >
        <Typography
          variant={isTabletLG ? 'BN-bold-s28-lh25' : 'BN-bold-s40-lh35'}
        >
          {t('Map.Start')}
        </Typography>
      </Button>
    </Flex>
  );
};

LevelsIndicator.propTypes = {
  allLevelsAmount: number.isRequired,
  completedLevelsAmount: number.isRequired,
  onPlayButtonClick: func.isRequired,
};

export default LevelsIndicator;
