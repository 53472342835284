export const styles = {
  dividerMobile: {
    horizontal: {
      margin: '16px 0',
    },
    vertical: {},
  },
  horizontal: {
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    height: '1px',
    margin: '32px 0',
    width: '100%',
  },
  vertical: {
    backgroundColor: 'rgba(255, 255, 255, 0.16)',
    height: '100%',
    width: '1px',
  },
};
