import { EllipseGradient } from '@components';
import { routes } from '@constants';
import { useScreenSize, useScrollToTop } from '@hooks';
import BottomGradient from '@pngs/bottomGradientMobile.png';
import Female from '@pngs/female.png';
import Male from '@pngs/male.png';
import NoiseImage from '@pngs/Noise5.png';
import Onboarding from '@pngs/onboarding.png';
import { authSlice, userApi } from '@store';
import { Button, Flex, Image, theme, Typography } from '@styles';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { onboardingPageLayoutByVariant } from './OnboardingPage.layoutProps';

const OnboardingPage = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { device, isLaptop, isMobile, isTabletLG, isTabletSM } =
    useScreenSize();
  const layout = onboardingPageLayoutByVariant[device || 'desktop'];

  const [updateOnboardingProgress] =
    userApi.useUpdateOnboardingProgressMutation();

  const { gender, isOnboardingPassed, onboardingState } = useSelector(
    (state) => state.authSlice
  );

  useEffect(() => {
    if (isOnboardingPassed) {
      navigate(routes.map, { state: { showRulesModal: true } });
    }
  }, [isOnboardingPassed]);

  const passFirstStep = () => {
    dispatch(authSlice.actions.setOnboardingState('gender'));
  };

  const passSecondStep = (value) => async () => {
    dispatch(authSlice.actions.setGender(value));

    await updateOnboardingProgress({
      gender,
    });

    dispatch(authSlice.actions.setOnboardingStatus(true));
  };

  const formByState = {
    gender: (
      <Flex flex={1} maxHeight="100%" px={layout.px} py={64}>
        <Flex
          flexDirection="column"
          gap={isTabletSM ? 16 : 32}
          maxHeight="100%"
          pb={isTabletSM ? 16 : 24}
          pt={24}
          px={isTabletSM ? 16 : 24}
          width="100%"
        >
          <Flex
            {...(isTabletLG && {
              alignItems: 'center',
              justifyContent: 'space-between',
            })}
            flexDirection={isTabletLG ? 'row' : 'column'}
          >
            <Typography
              color={theme.colors.white[70]}
              textAlign={isTabletLG ? 'left' : 'center'}
              variant={isTabletSM ? 'BN-bold-s28-lh33' : 'BN-bold-s42-lh50'}
            >
              {i18n.t('Onboarding.ChooseGender')}
            </Typography>
          </Flex>
          <Flex
            flex={1}
            flexDirection={isTabletSM ? 'column' : 'row'}
            gap={8}
            maxHeight="100%"
          >
            <Flex
              alignItems="center"
              backgroundColor={theme.colors.white[5]}
              borderRadius={4}
              flex={1 / 2}
              flexDirection="column"
              gap={isTabletSM ? 0 : '5%'}
              justifyContent="space-between"
              onClick={passSecondStep('Чоловік')}
              pt={isTabletSM ? '8px' : '5%'}
            >
              <Typography
                color={theme.colors.white[100]}
                textAlign="center"
                variant={
                  isTabletSM ? 'MS-semibold-s16-lh25' : 'MS-semibold-s32-lh50'
                }
              >
                {i18n.t('Onboarding.Male').toUpperCase()}
              </Typography>
              <Image
                maxWidth="100%"
                src={Male}
                width={!isLaptop ? '70%' : '100%'}
              />
            </Flex>
            <Flex
              alignItems="center"
              backgroundColor={theme.colors.white[5]}
              borderRadius={4}
              flex={1 / 2}
              flexDirection="column"
              gap={isTabletSM ? 0 : '5%'}
              justifyContent="space-between"
              onClick={passSecondStep('Жінка')}
              pt={isTabletSM ? '8px' : '5%'}
            >
              <Typography
                color={theme.colors.white[100]}
                textAlign="center"
                variant={
                  isTabletSM ? 'MS-semibold-s16-lh25' : 'MS-semibold-s32-lh50'
                }
              >
                {i18n.t('Onboarding.Female').toUpperCase()}
              </Typography>
              <Image
                maxWidth="100%"
                src={Female}
                width={!isLaptop ? '70%' : '100%'}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    ),
    intro: (
      <Flex flex={1} {...layout}>
        {!isTabletLG && (
          <Flex
            flexShrink={0}
            maxHeight={942}
            maxWidth={406}
            position="relative"
          >
            <EllipseGradient
              left={-12}
              position="absolute"
              top={-40}
              zIndex={-1}
            />
            <Image mixBlendMode="overlay" src={Onboarding} />
          </Flex>
        )}
        <Flex
          alignItems="center"
          backgroundColor={theme.colors.white[5]}
          borderRadius={4}
          flexDirection="column"
          gap={isTabletLG ? 27 : 0}
          justifyContent="space-between"
          mb={isTabletLG ? 0 : 23}
          mt={isTabletLG ? 24 : 23}
          p={24}
          position="relative"
          style={!isTabletLG ? { overflowY: 'auto' } : {}}
        >
          <Flex flexDirection="column">
            <Typography
              color={theme.colors.white[70]}
              mb={isTabletSM ? 16 : 24}
              mt={isTabletSM ? 16 : 0}
              variant={isTabletSM ? 'BN-bold-s24-lh21' : 'BN-bold-s42-lh50'}
            >
              {i18n.t('Onboarding.Welcome.Title')}
            </Typography>
            <Typography
              color={theme.colors.white[100]}
              mb={12}
              variant={
                isTabletSM
                  ? 'MS-semiregular-s14-lh22'
                  : 'MS-semiregular-s18-lh22'
              }
            >
              {i18n.t('Onboarding.Welcome.1')}
            </Typography>
            <Typography
              color={theme.colors.white[100]}
              mb={12}
              variant={
                isTabletSM
                  ? 'MS-semiregular-s14-lh22'
                  : 'MS-semiregular-s18-lh22'
              }
            >
              {i18n.t('Onboarding.Welcome.2')}
            </Typography>
            <Typography
              color={theme.colors.white[100]}
              mb={12}
              variant={
                isTabletSM
                  ? 'MS-semiregular-s14-lh22'
                  : 'MS-semiregular-s18-lh22'
              }
            >
              {i18n.t('Onboarding.Welcome.3')}
            </Typography>
            <Typography
              color={theme.colors.white[100]}
              mb={12}
              variant={
                isTabletSM
                  ? 'MS-semiregular-s14-lh22'
                  : 'MS-semiregular-s18-lh22'
              }
            >
              {i18n.t('Onboarding.Welcome.4')}
            </Typography>
          </Flex>
          <Button onClick={passFirstStep} variant="textButton">
            <Typography
              color={theme.colors.white[100]}
              variant="MS-bold-s16-lh25"
            >
              {i18n.t('Onboarding.Welcome.Button')}
            </Typography>
          </Button>
          {isTabletLG && (
            <Image
              bottom={-16}
              position="absolute"
              src={BottomGradient}
              zIndex={-1}
            />
          )}
        </Flex>
      </Flex>
    ),
  };

  useScrollToTop();

  return (
    <Flex
      backgroundBlendMode="overlay"
      backgroundImage={NoiseImage}
      backgroundSize="100%"
      {...(isMobile ? { height: '100%' } : { height: '100vh' })}
    >
      {formByState[onboardingState || 'intro']}
    </Flex>
  );
};

export default OnboardingPage;
