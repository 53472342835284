import { withFlexProps } from '@hocs';
import { Flex, theme } from '@styles';
import { number, string } from 'prop-types';
import React from 'react';

const Line = ({ lineColor, lineHeight }) => (
  <Flex backgroundColor={lineColor} flex={1} height={lineHeight} width="100%" />
);

Line.defaultProps = {
  lineColor: theme.colors.tundora,
  lineHeight: 2,
};

Line.propTypes = {
  lineColor: string,
  lineHeight: number,
};

export default withFlexProps(Line);
