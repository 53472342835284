import { Icon, Modal } from '@components';
import { LEARNIG_LINK } from '@constants';
import { useScreenSize } from '@hooks';
import { Flex, theme, Typography } from '@styles';
import { ReactComponent as Arrow } from '@svgs/arrow.svg';
import { ReactComponent as Learning } from '@svgs/learning.svg';
import { bool, func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Divider from '../Divider/Divider';
import RuleBlock from './RuleBlock';
import { getRuleBlocks } from './RulesModal.layoutProps';
import { styles } from './RulesModal.styles';

const RulesModal = ({ isVisible, onClose, setModalVisible }) => {
  const { t } = useTranslation();
  const { isTabletLG, isTabletSM } = useScreenSize();

  return (
    <Modal
      isVisible={isVisible}
      modalContainerProps={styles.modalContainer}
      modalMaxWidth="1072px"
      onClose={onClose}
      setModalVisible={setModalVisible}
    >
      <Flex
        {...styles.contentContainer}
        {...(isTabletLG && styles.contentContainerMobile)}
      >
        <Flex
          {...styles.scrollableContainer}
          {...(isTabletLG && styles.scrollableContainerMobile)}
        >
          <Typography
            marginRight={isTabletSM ? 'auto' : '0'}
            textDecoration="uppercase"
            variant={isTabletLG ? 'BN-bold-s28-lh25' : 'BN-bold-s64-lh77'}
          >
            {t('RulesModal.Title')}
          </Typography>
          <Flex
            {...styles.ruleBlocksContainer}
            {...(isTabletLG && styles.ruleBlocksContainerMobile)}
          >
            {getRuleBlocks({ isTabletSM, t }).map(({ rules, title }, index) => (
              <>
                <Flex flexDirection="row" width={isTabletLG ? '100%' : '50%'}>
                  <RuleBlock key={title} rules={rules} title={title} />
                  {index % 2 === 0 && !isTabletLG && (
                    <Divider variant="vertical" />
                  )}
                </Flex>
                {(index % 2 === 1 || isTabletLG) && (
                  <Divider variant="horizontal" />
                )}
              </>
            ))}
            <Typography
              variant={
                isTabletSM
                  ? 'MS-semiregular-s16-lh21'
                  : 'MS-semiregular-s20-lh22'
              }
            >
              {t('RulesModal.Note')}
            </Typography>
          </Flex>
          <Flex
            as="a"
            bg={theme.colors.vividYellow[50]}
            borderRadius="8px"
            href={LEARNIG_LINK}
            justifyContent="space-between"
            mt={32}
            px="16px"
            py="8px"
            style={{
              textDecoration: 'none',
            }}
            target="_blank"
            width="100%"
          >
            <Flex>
              <Flex my="auto">
                <Icon
                  height={isTabletLG ? 20 : 30}
                  mr={15}
                  SVG={Learning}
                  width={isTabletLG ? 14 : 20}
                />
              </Flex>
              <Typography
                alignSelf="center"
                color={theme.colors.black[100]}
                variant={
                  isTabletSM
                    ? 'MS-semiregular-s16-lh21'
                    : 'MS-semiregular-s20-lh22'
                }
              >
                {t('RulesModal.Other')}
              </Typography>
            </Flex>
            <Flex my="auto">
              <Icon
                height={isTabletLG ? 24 : 40}
                mr={15}
                SVG={Arrow}
                width={isTabletLG ? 24 : 40}
              />
            </Flex>
          </Flex>
        </Flex>
        <Typography
          onClick={onClose}
          style={styles.submitButton}
          variant="MS-bold-s16-lh25"
        >
          {t('RulesModal.Submit')}
        </Typography>
      </Flex>
    </Modal>
  );
};

RulesModal.defaultProps = {
  onClose: () => {},
};

RulesModal.propTypes = {
  isVisible: bool.isRequired,
  onClose: func,
  setModalVisible: func.isRequired,
};

export default RulesModal;
