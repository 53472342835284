import { theme } from '@styles';

export const scenarioPageLayoutByVariant = {
  desktop: {
    pb: 64,
    pl: 104,
    pr: 104,
    pt: 64,
  },
  laptop: {
    pb: 24,
    pl: 64,
    pr: 64,
    pt: 24,
  },
  mobile: {
    pb: 16,
    pl: 16,
    pr: 16,
    pt: 40,
  },
  tablet: {
    pb: 16,
    pl: 16,
    pr: 16,
    pt: 40,
  },
};

export const finishSection = {
  failed: {
    color: theme.colors.alto[30],
    subtitle: 'Scenario.Passed.Failed.Subtitle',
    title: 'Scenario.Passed.Failed.Title',
  },
  success: {
    color: theme.colors.white[100],
    subtitle: 'Scenario.Passed.Success.Subtitle',
    title: 'Scenario.Passed.Success.Title',
  },
};

export const FINISH_SECTION_SUBTITLE_BY_COMPLETED_STEPS_AMOUNT = {
  0: 'Scenario.Passed.Failed.Subtitle',
  1: 'Scenario.Passed.Moderate.Subtitle',
  2: 'Scenario.Passed.Moderate.Subtitle',
  3: 'Scenario.Passed.Success.Subtitle',
};
