import { checkIfFormStateValueIsAccessibleByInputName } from '@helpers';
import { withFlexProps } from '@hocs';
import { Flex, TextInput, theme } from '@styles';
import { bool, number, oneOf, string } from 'prop-types';
import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '../Icon';
import { inputLayoutByVariant } from './Input.layoutProps';

const Input = ({
  autoCapitalize,
  disabled,
  maxLength,
  name,
  type,
  variant,
}) => {
  const { i18n } = useTranslation();
  const [isInputFocused, setInputFocused] = useState(false);

  const inputProps = inputLayoutByVariant[variant];

  const {
    formState: { errors },
  } = useFormContext();

  const {
    field: { onChange: onFormValueChange, value: formValue },
  } = useController({
    name,
  });

  const handleFocus = () => {
    if (!isInputFocused) setInputFocused(true);
  };

  const handleBlur = () => {
    setInputFocused(false);
  };

  const isError = name.includes('.')
    ? checkIfFormStateValueIsAccessibleByInputName({
        formStateValue: errors,
        inputName: name,
      })
    : !!errors[name];

  return (
    <Flex
      alignItems="center"
      bg={theme.colors.white[10]}
      flexDirection="row"
      height={64}
      px={24}
      width="100%"
      {...(isInputFocused && {
        border: `1px solid ${theme.colors.white[40]}`,
      })}
      {...(isError && { border: `1px solid ${theme.colors.red[100]}` })}
    >
      <Icon mr={16} SVG={inputProps.svg} />
      <TextInput
        disabled={disabled}
        flex={1}
        maxLength={maxLength}
        name={name}
        onBlur={handleBlur}
        onChange={onFormValueChange}
        onFocus={handleFocus}
        placeholder={i18n.t(inputProps.placeholder)}
        type={type}
        value={formValue}
        {...(autoCapitalize && {
          autoCapitalize,
        })}
      />
    </Flex>
  );
};

Input.defaultProps = {
  autoCapitalize: null,
  disabled: false,
  maxLength: null,
};

Input.propTypes = {
  autoCapitalize: bool,
  disabled: bool,
  maxLength: number,
  name: string.isRequired,
  type: oneOf(['email', 'password', 'text']).isRequired,
  variant: oneOf([
    'email',
    'gender',
    'newPassword',
    'oldPassword',
    'password',
    'repeatNewPassword',
    'repeatPassword',
    'username',
  ]).isRequired,
};

export default withFlexProps(Input);
