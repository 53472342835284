export const authTemplateLayoutByVariant = {
  desktop: {
    subtitleVariant: 'MS-thin-s24-lh37',
    titleVariant: 'BN-bold-s80-lh84',
  },
  laptop: {
    subtitleVariant: 'MS-thin-s24-lh37',
    titleVariant: 'BN-bold-s80-lh84',
  },
  mobile: {
    subtitleVariant: 'MS-thin-s16-lh25',
    titleVariant: 'BN-bold-s48-lh57',
  },
  tablet: {
    subtitleVariant: 'MS-thin-s24-lh37',
    titleVariant: 'BN-bold-s80-lh84',
  },
};
