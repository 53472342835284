import { Icon } from '@components';
import { withFlexProps } from '@hocs';
import { Flex, Typography } from '@styles';
import { ReactComponent as DragIcon } from '@svgs/drag.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { styles } from './DragPhotoBlock.styles';

const DragPhotoBlock = () => {
  const { t } = useTranslation();

  return (
    <Flex {...styles.container}>
      <Icon height={40} SVG={DragIcon} width={40} />
      <Typography variant="MS-regular-s14-lh22">
        {t('Scenario.DragPhoto')}
      </Typography>
    </Flex>
  );
};

export default withFlexProps(DragPhotoBlock);
