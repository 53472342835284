import {
  arrayOf,
  bool,
  node,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';
import React, { useEffect, useState } from 'react';

const UNMOUNTED = 'unmounted';
const EXITED = 'exited';
const ENTERING = 'entering';
const ENTERED = 'entered';
const EXITING = 'exiting';

const transitionStyles = {
  entered: { opacity: 1 },
  entering: { opacity: 0 },
  exited: { opacity: 0 },
  exiting: { opacity: 0 },
};

const FadeInOut = ({ children, className, duration, show, style }) => {
  const [status, setStatus] = useState(UNMOUNTED);

  const performEnter = () => {
    setStatus(ENTERING);
    setTimeout(() => {
      setStatus(ENTERED);
    }, 50);
  };

  const performExit = () => {
    setStatus(EXITING);
    setTimeout(() => {
      setStatus(EXITED);
    }, duration);
  };

  const updateStatus = (nextStatus) => {
    if (nextStatus) {
      if (nextStatus === ENTERING) {
        performEnter();
      } else {
        performExit();
      }
    } else if (status === EXITED) {
      setStatus(UNMOUNTED);
    }
  };

  useEffect(() => {
    if (show) {
      performEnter();
    }
  }, []);

  useEffect(() => {
    let nextStatus = null;
    if (show) {
      if (status !== ENTERING && status !== ENTERED) {
        nextStatus = ENTERING;
      }
    } else if (status === ENTERING || status === ENTERED) {
      nextStatus = EXITING;
    }
    updateStatus(nextStatus);
  }, [show]);

  if (status === UNMOUNTED) {
    return null;
  }

  return (
    <div
      className={className}
      style={{
        ...style,
        opacity: 0.1,
        transition: `opacity ${duration}ms ease-in-out`,
        ...transitionStyles[status],
      }}
    >
      {children}
    </div>
  );
};

FadeInOut.defaultProps = {
  className: '',
  duration: 300,
  show: false,
  style: null,
};

FadeInOut.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  className: string,
  duration: number,
  show: bool,
  style: object,
};

export default FadeInOut;
