import { theme } from '@styles';

export const styles = {
  completedLevelsIndicator: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(255, 245, 4, 0.24)',
    borderRadius: '9px 0px 0px 9px',
    marginRight: 'auto',
  },
  completedLevelsText: {
    color: theme.colors.white,
    left: 67,
    lineHeight: 'normal',
    position: 'absolute',
    top: 12,
    variant: 'MS-regular-s32-lh50',
  },
  completedLevelsTextMobile: {
    left: '43px',
    top: '5px',
    variant: 'MS-regular-s20-lh31',
  },
  container: {
    alignItems: 'center',
    bottom: '44px',
    position: 'absolute',
    right: '64px',
  },
  containerMobile: {
    bottom: '34px',
    right: '32px',
  },
  levelsBar: {
    backdropFilter: 'blur(2px)',
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    borderBottom: '3px solid rgba(255, 255, 255, 0.16)',
    borderLeft: '3px solid rgba(255, 255, 255, 0.16)',
    borderRadius: '12px 0px 0px 12px',
    borderTop: '3px solid rgba(255, 255, 255, 0.16)',
    height: '64px',
    position: 'relative',
    width: '194px',
  },
  levelsBarMobile: {
    height: '40px',
    width: '121px',
  },
  playButton: {
    alignItems: 'center',
    backgroundColor: '#FFF504',
    height: '91px',
    justifyContent: 'center',
    width: '149px',
  },
  playButtonMobile: {
    height: '64px',
    width: '104px',
  },
};

export const calculateCompletedLevelsIndicatorWidth = ({
  allLevelsAmount,
  completedLevelsAmount,
}) => {
  const completedLevelsIndicatorWidth =
    (completedLevelsAmount / allLevelsAmount) * 100;
  return `${completedLevelsIndicatorWidth}%`;
};
