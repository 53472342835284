import { SquareButton } from '@components';
import { languages, routes } from '@constants';
import { useBackgroundAudioSwitch, useScreenSize } from '@hooks';
import { authSlice } from '@store';
import { Flex, theme, Typography } from '@styles';
import { ReactComponent as ExitIcon } from '@svgs/exit.svg';
import { ReactComponent as InfoIcon } from '@svgs/info.svg';
import { ReactComponent as SoundOffIcon } from '@svgs/sound-off.svg';
import { ReactComponent as SoundOnIcon } from '@svgs/sound-on.svg';
import { arrayOf, bool, func, object, oneOf } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import RulesModal from '../RulesModal/RulesModal';
import { styles } from './ControlButtons.styles';

const ControlButtons = ({
  buttonContainerProps,
  containerProps,
  controls,
  direction,
  isRulesModalVisible,
  onModalClose,
  setRulesModalVisible,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const { i18n } = useTranslation();
  const { language } = useSelector((state) => state.authSlice);
  const { isAudioPlaying, toggleAudio } = useBackgroundAudioSwitch();

  const handleLanguageSwitch = () => {
    const newLanguage = language === 'en' ? 'ua' : 'en';
    dispatch(authSlice.actions.setLanguage(newLanguage));

    i18n.changeLanguage(newLanguage);
  };

  const handleInfoClick = () => {
    setRulesModalVisible(true);
  };

  const handleExitClick = () => {
    navigate(routes.map);
  };

  const controlsOptions = {
    exit: (
      <SquareButton
        containerProps={{
          ...buttonContainerProps,
          backgroundColor: 'rgba(255, 162, 162, 0.16)',
        }}
        onClick={handleExitClick}
        SVG={ExitIcon}
      />
    ),
    info: <SquareButton onClick={handleInfoClick} SVG={InfoIcon} />,
    language: (
      <SquareButton
        containerProps={buttonContainerProps}
        onClick={handleLanguageSwitch}
      >
        <Typography
          color={theme.colors.white[100]}
          variant={isMobile ? 'MS-regular-s16-lh25' : 'MS-regular-s18-lh22'}
        >
          {languages[language]?.displayText}
        </Typography>
      </SquareButton>
    ),
    sound: (
      <SquareButton
        containerProps={buttonContainerProps}
        onClick={toggleAudio}
        SVG={isAudioPlaying ? SoundOnIcon : SoundOffIcon}
      />
    ),
  };

  return (
    <Flex {...styles.container} {...containerProps} flexDirection={direction}>
      {controls.map((control) => controlsOptions[control])}
      <RulesModal
        isVisible={isRulesModalVisible}
        onClose={onModalClose}
        setModalVisible={setRulesModalVisible}
      />
    </Flex>
  );
};

ControlButtons.defaultProps = {
  buttonContainerProps: {},
  containerProps: {},
  direction: 'row',
  isRulesModalVisible: false,
  onModalClose: null,
  setRulesModalVisible: () => {},
};

ControlButtons.propTypes = {
  buttonContainerProps: object,
  containerProps: object,
  controls: arrayOf(['exit', 'info', 'language', 'sound']).isRequired,
  direction: oneOf(['row', 'column']),
  isRulesModalVisible: bool,
  onModalClose: func,
  setRulesModalVisible: func,
};

export default ControlButtons;
