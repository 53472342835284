import { Modal } from '@components';
import { routes } from '@constants';
import { useScreenSize } from '@hooks';
import Female from '@pngs/female.png';
import Male from '@pngs/male.png';
import { userApi } from '@store';
import { Button, Flex, Image, theme, Typography } from '@styles';
import { bool, func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getAvatarStyles } from './CompletionModal.styles';

const CompletionModal = ({ isModalVisible, setModalVisible }) => {
  const { i18n } = useTranslation();
  const { isTabletLG, isTabletSM } = useScreenSize();
  const navigate = useNavigate();

  const { data } = userApi.useGetUserQuery();
  const user = data?.data;

  const onFinishPress = () => {
    setModalVisible(false);
    navigate(routes.map);
  };

  const bodyTextVariant = isTabletSM
    ? 'MS-semiregular-s16-lh21'
    : 'MS-semiregular-s24-lh30';

  return (
    <Modal
      innerContainerProps={{ height: '100%' }}
      isVisible={isModalVisible}
      modalContainerProps={{
        backgroundColor: theme.colors.white[5],
        border: 'none',
        borderRadius: isTabletLG ? '16px' : '4px',
        ...(isTabletSM && { height: '90%' }),
        width: '70%',
      }}
      modalMaxHeight="100%"
      modalMaxWidth="100%"
      setModalVisible={setModalVisible}
      withBackdropNoise
      withCloseIcon={false}
    >
      <Flex
        alignItems="center"
        color={theme.colors.white[100]}
        flexDirection="column"
        justifyContent="space-between"
        px={isTabletSM ? 16 : 96}
        py={isTabletSM ? 40 : 32}
        width="100%"
      >
        <Image
          {...getAvatarStyles({ isTabletSM })}
          src={user?.gender === 'Чоловік' ? Male : Female}
        />
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          gap={32}
          width="100%"
        >
          <Typography
            variant={isTabletSM ? 'BN-bold-s32-lh28' : 'BN-bold-s56-lh67'}
          >
            {i18n.t('Scenario.PassedAll.Title')}
          </Typography>
          <Typography variant={bodyTextVariant}>
            {i18n.t('Scenario.PassedAll.Subtitle')}
          </Typography>
          <Typography variant={bodyTextVariant}>
            {i18n.t('Scenario.PassedAll.ToPerfectKnowledge')}
            <b>{i18n.t('Scenario.PassedAll.CollectAllStars')}</b>
          </Typography>
          <Typography variant={bodyTextVariant}>
            {i18n.t('Scenario.PassedAll.Congratulations')}
          </Typography>
        </Flex>
        <Button onClick={onFinishPress} p="22px 47px" variant="textButton">
          <Typography
            color={theme.colors.white[100]}
            variant="MS-bold-s16-lh25"
          >
            {i18n.t('Scenario.PassedAll.Button')}
          </Typography>
        </Button>
      </Flex>
    </Modal>
  );
};

CompletionModal.propTypes = {
  isModalVisible: bool.isRequired,
  setModalVisible: func.isRequired,
};

export default CompletionModal;
