import { useScreenSize } from '@hooks';
import { Flex } from '@styles';
import { oneOf } from 'prop-types';
import React from 'react';

import { styles } from './Divider.styles';

const Divider = ({ variant }) => {
  const { isTabletSM } = useScreenSize();

  return (
    <Flex
      style={{
        ...styles[variant],
        ...(isTabletSM && styles.dividerMobile[variant]),
      }}
    />
  );
};

Divider.defaultProps = {
  variant: 'horizontal',
};

Divider.propTypes = {
  variant: oneOf(['horizontal', 'vertical']),
};

export default Divider;
