export const mainPageLayoutByVariant = {
  desktop: {
    pb: 41,
    pl: 0,
    pr: 104,
    pt: 41,
  },
  laptop: {
    pb: 41,
    pl: 0,
    pr: 64,
    pt: 41,
  },
  mobile: {
    pb: 16,
    pl: 16,
    pr: 16,
    pt: 40,
  },
  tablet: {
    pb: 16,
    pl: 16,
    pr: 16,
    pt: 40,
  },
};
