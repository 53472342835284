import { withFlexProps } from '@hocs';
import { elementType, number } from 'prop-types';
import React from 'react';

const Icon = ({ SVG, height, width }) => <SVG height={height} width={width} />;

Icon.propTypes = {
  SVG: elementType.isRequired,
  height: number,
  width: number,
};

Icon.defaultProps = {
  height: 24,
  width: 24,
};

export default withFlexProps(Icon);
