/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {};

export const scenarioSlice = createSlice({
  initialState,
  name: 'scenarioSlice',
  reducers: {
    reset: () => initialState,
  },
});

export const scenarioSlicePersistedReducer = persistReducer(
  {
    key: 'scenarioSlice',
    storage,
  },
  scenarioSlice.reducer
);
