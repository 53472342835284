/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';

import { devices } from '../utils';

export const useScreenSize = () => {
  const [screenSize, getDimension] = useState({});
  const setDimension = () => {
    getDimension({
      device:
        window.innerWidth <= devices.mobile
          ? 'mobile'
          : window.innerWidth <= devices.tabletLG
            ? 'tablet'
            : window.innerWidth <= devices.laptop
              ? 'laptop'
              : 'desktop',
      dynamicHeight: window.innerHeight,
      dynamicWidth: window.innerWidth,
      isDesktop: window.innerWidth > devices.laptop,
      isLaptop: window.innerWidth <= devices.laptop,
      isMobile: window.innerWidth <= devices.mobile,
      isTabletLG: window.innerWidth <= devices.tabletLG,
      isTabletSM: window.innerWidth <= devices.tabletSM,
    });
  };

  useEffect(() => {
    setDimension();
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', setDimension);

      return () => {
        window.removeEventListener('resize', setDimension);
      };
    }
    return 0;
  }, [screenSize]);

  return screenSize;
};
