/* eslint-disable no-nested-ternary */
import { EllipseGradient, FadeInOut, Stepper } from '@components';
import { useScreenSize } from '@hooks';
import { Button, Flex, theme, Typography } from '@styles';
import { arrayOf, func, number, object } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const IncorrectOverlay = ({
  currentStep,
  onContinue,
  onStepChange,
  steps,
  validatedAnswer,
}) => {
  const { isMobile, isTabletSM } = useScreenSize();
  const i18n = useTranslation();

  return (
    <FadeInOut
      duration={300}
      show={validatedAnswer && !validatedAnswer?.is_correct}
      style={{
        height: '100%',
        overflowY: 'auto',
        width: '100%',
        ...(isTabletSM && { padding: '10px' }),
      }}
    >
      <Flex
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        p={isMobile ? 0 : isTabletSM ? 16 : 24}
        width="100%"
      >
        <Stepper
          currentStep={currentStep}
          isDisabled
          onStepChange={onStepChange}
          steps={steps}
        />
        <Flex
          flexDirection="column"
          gap={isTabletSM ? 0 : 64}
          height="100%"
          justifyContent="space-between"
          mt={isTabletSM ? 16 : 131}
        >
          {isTabletSM ? (
            <Flex flexDirection="column" gap={8}>
              <Typography
                color={theme.colors.red[50]}
                variant="BN-bold-s56-lh67"
              >
                {i18n.t('Scenario.Wrong.Title')}
              </Typography>
              <Typography
                color={theme.colors.white[100]}
                variant="MS-semiregular-s18-lh22"
              >
                {validatedAnswer?.explanation}
              </Typography>
            </Flex>
          ) : (
            <Flex flexDirection="column">
              <Typography
                color={theme.colors.red[50]}
                variant="BN-bold-s92-lh100"
              >
                {i18n.t('Scenario.Wrong.Title')}
              </Typography>
              <Typography
                color={theme.colors.white[100]}
                mt={5}
                variant="MS-regular-s26-lh32"
              >
                {validatedAnswer?.explanation}
              </Typography>
            </Flex>
          )}
          <Flex justifyContent="flex-end" {...(isTabletSM && { mt: 10 })}>
            <Flex
              backgroundColor={theme.colors.white[10]}
              borderRadius="4px"
              flex={isTabletSM ? 1 : 1 / 2}
              height={isTabletSM ? 69 : 100}
            >
              <Button
                height="100%"
                onClick={onContinue}
                variant="textButton"
                width="100%"
              >
                <Typography
                  color={theme.colors.white[100]}
                  variant={
                    isTabletSM ? 'MS-semibold-s16-lh25' : 'MS-semibold-s24-lh30'
                  }
                >
                  {i18n.t('Scenario.Wrong.Button')}
                </Typography>
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <EllipseGradient
          customColor={theme.colors.red[100]}
          height={1581}
          left={-120}
          opacity={0.8}
          position="absolute"
          style={{ pointerEvents: 'none' }}
          top={-160}
          width={356}
        />
        <EllipseGradient
          customColor={theme.colors.red[100]}
          height={1581}
          opacity={0.8}
          position="absolute"
          right={-120}
          style={{ pointerEvents: 'none' }}
          top={-160}
          width={356}
        />
      </Flex>
    </FadeInOut>
  );
};

IncorrectOverlay.propTypes = {
  currentStep: number.isRequired,
  onContinue: func.isRequired,
  onStepChange: func.isRequired,
  steps: arrayOf(number).isRequired,
  validatedAnswer: object.isRequired,
};

export default IncorrectOverlay;
