import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { api } from './api';
import { authSlice, authSlicePersistedReducer } from './auth';
import { scenarioSlice, scenarioSlicePersistedReducer } from './scenario';
import { userSlice, userSlicePersistedReducer } from './user';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [authSlice.name]: authSlicePersistedReducer,
  [userSlice.name]: userSlicePersistedReducer,
  [scenarioSlice.name]: scenarioSlicePersistedReducer,
});

const persistConfig = {
  blacklist: [api.reducerPath],
  key: 'persistor',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
