import { FadeInOut, StepCard } from '@components';
import { useScreenSize } from '@hooks';
import { Flex } from '@styles';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import React from 'react';

const StepsSection = ({
  currentStep,
  isCardShown,
  onContinue,
  onFinish,
  onStepChange,
  onStepFinish,
  scenario,
}) => {
  const { isTabletSM } = useScreenSize();

  return (
    <FadeInOut duration={500} show={isCardShown}>
      <Flex
        height="100vh"
        width="100vw"
        {...(isTabletSM && { borderRadius: 24, pb: 34, pt: 48, px: 16 })}
      >
        <StepCard
          backgroundImage={scenario?.background_image}
          currentStep={currentStep}
          onContinue={onContinue}
          onFinish={onFinish}
          onStepChange={onStepChange}
          onStepFinish={onStepFinish}
          steps={scenario?.steps}
        />
      </Flex>
    </FadeInOut>
  );
};

StepsSection.propTypes = {
  currentStep: number.isRequired,
  isCardShown: bool.isRequired,
  onContinue: func.isRequired,
  onFinish: func.isRequired,
  onStepChange: func.isRequired,
  onStepFinish: func.isRequired,
  scenario: shape({
    background_image: string,
    steps: arrayOf(number),
  }).isRequired,
};

export default StepsSection;
