import { useScreenSize } from '@hooks';
import { Flex, Typography } from '@styles';
import { arrayOf, node, oneOf, shape, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getRulesListStyles } from './RulesModal.styles';

const RuleBlock = ({ rules, title }) => {
  const { t } = useTranslation();
  const { isTabletSM } = useScreenSize();

  const textVariant = isTabletSM
    ? 'MS-semiregular-s16-lh21'
    : 'MS-semiregular-s20-lh22';
  const titleVariant = isTabletSM ? 'MS-semibold-s16-lh25' : 'MS-bold-s20-lh21';

  return (
    <Flex
      flexDirection="column"
      p={isTabletSM ? '16px 0' : '16px 64px'}
      width="100%"
    >
      <Typography variant={titleVariant}>{t(title)}</Typography>
      <Flex style={getRulesListStyles({ isTabletSM })}>
        {rules.map(({ rule }, index) =>
          typeof rule === 'string' ? (
            <Typography key={index} py={2} variant={textVariant}>
              {rule}
            </Typography>
          ) : (
            rule
          )
        )}
      </Flex>
      {/* <Flex height="1px" style={{ backgroundColor: 'white' }} width="100%" /> */}
    </Flex>
  );
};

RuleBlock.propTypes = {
  rules: arrayOf(
    shape({ rule: oneOf([node, string]), subrules: arrayOf(string) })
  ).isRequired,
  title: string.isRequired,
};

export default RuleBlock;
