import styled, { css } from 'styled-components';
import { border, color, flexbox, layout, position, space } from 'styled-system';

const Flex = styled.div`
  ${({
    backdropFilter,
    backgroundBlendMode,
    backgroundColor,
    backgroundImage,
    backgroundPosition,
    backgroundPositionX,
    backgroundRepeat,
    backgroundSize,
    gap,
    pointer,
    transform,
  }) => css`
    display: flex;
    ${gap && `gap: ${gap}px;`}
    ${backgroundImage && `background-image: url("${backgroundImage}");`}
    ${backgroundSize && `background-size: ${backgroundSize};`}
    ${backgroundPosition && `background-position: ${backgroundPosition};`}
    ${backgroundRepeat && `background-repeat: ${backgroundRepeat};`}
    ${backdropFilter && `backdrop-filter: ${backdropFilter};`}
    ${transform && `transform: ${transform};`}
    ${backgroundPositionX && `background-position-x: ${backgroundPositionX};`}
    ${pointer && 'cursor: pointer;'}
    ${backgroundBlendMode && `background-blend-mode: ${backgroundBlendMode};`}
    ${backgroundColor && `background-color: ${backgroundColor};`}

    ${border};
    ${color};
    ${flexbox};
    ${layout};
    ${position};
    ${space};
  `}
`;

export default Flex;
