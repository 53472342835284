import { BackgroundAudioPlayer } from '@components';
import isPropValid from '@emotion/is-prop-valid';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as maptilersdk from '@maptiler/sdk';
import { router } from '@navigation';
import { persistor, store } from '@store';
import { ResetStyle, theme } from '@styles';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import i18n from './i18n';

maptilersdk.config.apiKey = process.env.REACT_APP_MAPTILER_API_KEY;

const App = () => {
  // const { isTabletSM } = useScreenSize();

  // const lockOrientation = () => {
  //   if (isTabletSM) {
  //     if (window.screen.orientation.includes('portrait')) {
  //       window.screen.orientation.lock();
  //     } else {
  //       window.screen.orientation.lock('portrait');
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.screen.addEventListener('orientationchange', lockOrientation);
  //   return () => {
  //     window.screen.removeEventListener('orientationchange', lockOrientation);
  //   };
  // }, []);

  const handlePersistGateBeforeLift = () => {
    const { language } = store.getState().authSlice;

    i18n.changeLanguage(language);
  };

  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        onBeforeLift={handlePersistGateBeforeLift}
        persistor={persistor}
      >
        <I18nextProvider i18n={i18n}>
          <StyleSheetManager
            enableVendorPrefixes
            shouldForwardProp={(propName, elementToBeRendered) =>
              typeof elementToBeRendered === 'string'
                ? isPropValid(propName)
                : true
            }
          >
            <ThemeProvider theme={theme}>
              <ResetStyle />
              <RouterProvider router={router} />
              <BackgroundAudioPlayer />
            </ThemeProvider>
          </StyleSheetManager>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
};
export default App;
