/* eslint-disable no-nested-ternary */
import { Modal } from '@components';
import { useScreenSize } from '@hooks';
import { Button, Flex, theme, Typography } from '@styles';
import { bool, func, object } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ScenarioCardModal = ({ isModalVisible, scenario, setModalVisible }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { isTabletLG, isTabletSM } = useScreenSize();

  const onStartPress = () => {
    setModalVisible(false);
    navigate(`/scenarios/${scenario?.id}`);
  };

  return (
    <Modal
      innerContainerProps={{
        minHeight: 'fit-content',
      }}
      isVisible={isModalVisible}
      modalContainerProps={{
        backgroundColor: theme.colors.white[5],
        border: 'none',
        borderRadius: isTabletLG ? '24px' : '4px',
        minHeight: isTabletLG ? 'fit-content' : 450,
        minWidth: '50%',
      }}
      modalMaxWidth="100%"
      setModalVisible={setModalVisible}
      withBackdropNoise
    >
      <Flex
        flexDirection="column"
        gap={isTabletSM ? 40 : 56}
        pb={isTabletSM ? 16 : 40}
        pt={isTabletSM ? 64 : 80}
        px={isTabletSM ? 16 : 24}
        width="100%"
      >
        <Typography
          color={theme.colors.white[100]}
          textAlign="center"
          variant={
            isTabletSM
              ? 'BN-bold-s40-lh35'
              : isTabletLG
                ? 'BN-bold-s48-lh57'
                : 'BN-bold-s80-lh84'
          }
        >
          {scenario?.scenario?.title}
        </Typography>
        <Typography
          color={theme.colors.vividYellow[80]}
          textAlign="center"
          variant={isTabletSM ? 'MS-semibold-s22-lh34' : 'MS-semibold-s32-lh50'}
        >
          {scenario?.subtitle}
        </Typography>
        <Button
          m={isTabletSM ? 0 : 12}
          onClick={onStartPress}
          variant="textButton"
        >
          <Typography
            color={theme.colors.white[100]}
            variant="MS-bold-s16-lh25"
          >
            {i18n.t('Scenario.StartButton')}
          </Typography>
        </Button>
      </Flex>
    </Modal>
  );
};

ScenarioCardModal.propTypes = {
  isModalVisible: bool.isRequired,
  scenario: object.isRequired,
  setModalVisible: func.isRequired,
};

export default ScenarioCardModal;
