import { Input } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useScreenSize } from '@hooks';
import { authApi, authSlice } from '@store';
import { Flex, theme, Typography } from '@styles';
import { schemas } from '@utils';
import { func } from 'prop-types';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getErrorNotificationText } from 'src/helpers/error';

import AuthTemplate from './AuthTemplate';

const SignUpForm = ({ onSignUpButtonClick, setAuthModalState }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { isTabletSM } = useScreenSize();

  const [signUp, { isLoading: isSignUpMutationLoading }] =
    authApi.useSignUpMutation();

  const { clearErrors, control, formState, handleSubmit, reset, setError } =
    useForm({
      defaultValues: {
        password: '',
        repeatPassword: '',
        username: '',
      },
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(schemas.signUpForm),
    });

  const handleLogInButtonClick = () => {
    setAuthModalState('signIn');
  };

  const handleSignUpButtonClick = () => {
    handleSubmit(async (formData) => {
      try {
        clearErrors('server');

        const { data } = await signUp({
          password: formData.password,
          username: formData.username,
        }).unwrap();
        dispatch(authSlice.actions.setTokens(data));
        dispatch(authSlice.actions.setUserId(data?.id));

        reset();
        setAuthModalState('signIn');

        onSignUpButtonClick(false);
      } catch ({ data }) {
        setError('server', {
          message: data.message,
          type: 'server',
        });
      }
    })();
  };

  const errorNotificationText = getErrorNotificationText(formState.errors);
  const isErrorNotificationVisible =
    !!errorNotificationText || formState.isSubmitted;

  return (
    <FormProvider control={control} formState={formState}>
      <AuthTemplate
        buttonDisabled={!formState.isValid || isSignUpMutationLoading}
        buttonText={i18n.t('Auth.SignUp.Button')}
        formComponent={
          <Flex
            flexDirection="column"
            height="100%"
            mb={isTabletSM ? 30 : '5px'}
            mt={isTabletSM ? 30 : 24}
            width="100%"
          >
            <Input mb={3} name="username" type="text" variant="username" />
            <Input mb={3} name="password" type="password" variant="password" />
            <Input
              name="repeatPassword"
              type="password"
              variant="repeatPassword"
            />
            <Typography
              color={theme.colors.red[100]}
              height={38}
              style={{
                lineHeight: '18px',
                marginTop: '2px',
                textAlign: 'center',
              }}
              variant="MS-regular-s16-lh25"
            >
              {isErrorNotificationVisible &&
                (i18n.t(errorNotificationText) || errorNotificationText)}
            </Typography>
          </Flex>
        }
        onMainButtonClick={handleSignUpButtonClick}
        onSubtitleButtonClick={handleLogInButtonClick}
        subButtonText={i18n.t('Auth.SignUp.SubButton')}
        titleText={i18n.t('Auth.SignUp.Title')}
      />
    </FormProvider>
  );
};

SignUpForm.propTypes = {
  onSignUpButtonClick: func.isRequired,
  setAuthModalState: func.isRequired,
};

export default SignUpForm;
