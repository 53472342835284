import { routes } from '@constants';
import { MainPage, MapPage, OnboardingPage, ScenarioPage } from '@screens';
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

const routeObject = [
  {
    element: <MainPage />,
    path: routes.main,
  },
  {
    element: <MapPage />,
    path: routes.map,
  },
  {
    element: <OnboardingPage />,
    path: routes.onboarding,
  },
  {
    element: <ScenarioPage />,
    path: routes.scenario,
  },
];

const router = createBrowserRouter(routeObject);

export { routeObject, router };
