import { userSlice } from '@store';
import { useDispatch, useSelector } from 'react-redux';

export const useBackgroundAudioSwitch = () => {
  const dispatch = useDispatch();

  const { isAudioPlaying, isAudioTriggered } = useSelector(
    (state) => state.userSlice
  );

  const toggleAudio = () => {
    dispatch(userSlice.actions.setIsAudioPlaying(!isAudioPlaying));
  };

  const turnAudioOn = () => {
    dispatch(userSlice.actions.setIsAudioPlaying(true));
  };
  const turnAudioOff = () => {
    dispatch(userSlice.actions.setIsAudioPlaying(false));
  };

  const triggerAudio = () => {
    dispatch(userSlice.actions.setIsAudioTriggered(true));
  };

  const clearAudioTrigger = () => {
    dispatch(userSlice.actions.setIsAudioTriggered(false));
  };

  return {
    clearAudioTrigger,
    isAudioPlaying,
    isAudioTriggered,
    toggleAudio,
    triggerAudio,
    turnAudioOff,
    turnAudioOn,
  };
};
