import NoiseImage from '@pngs/Noise5.png';
import styled, { css } from 'styled-components';
import { layout, space } from 'styled-system';

const Modal = styled.dialog`
  ${({
    backgroundColor,
    border,
    borderRadius,
    height,
    maxHeight,
    maxWidth,
    padding,
    theme,
    width,
    withBackdropNoise,
  }) => css`
    border: ${border || 'none'};
    padding: ${padding || 0};
    background-color: ${backgroundColor || theme.colors.greenKelp[100]};
    background-size: contain;
    border-radius: ${borderRadius || '8px'};
    ${maxHeight && `max-height: ${maxHeight};`}
    ${maxWidth && `max-width: ${maxWidth};`}
    ${width && `width: ${width};`}
    ${height && `height: ${height};`}
    min-height: 450px;
    &::backdrop {
      background-color: #000000cc;
      backdrop-filter: blur(9px);
      ${withBackdropNoise && `background-image: url(${NoiseImage}); `}
      background-size: contain;
    }

    ${space};
    ${layout};
  `}
`;

export default Modal;
