import { Icon } from '@components';
import { withFlexProps } from '@hocs';
import { Flex } from '@styles';
import { ReactComponent as EmptyStarIcon } from '@svgs/empty-star.svg';
import { ReactComponent as FilledStarIcon } from '@svgs/filled-star.svg';
import { number, object } from 'prop-types';
import React from 'react';

const StarsGroup = ({
  allStarsAmount,
  completedStarsAmount,
  containerProps,
}) => {
  const stars = new Array(allStarsAmount).fill(null);
  return (
    <Flex gap={16} {...containerProps}>
      {stars.map((_, index) => (
        <Icon
          as={index < completedStarsAmount ? FilledStarIcon : EmptyStarIcon}
          height={96}
          width={96}
        />
      ))}
    </Flex>
  );
};

StarsGroup.defaultProps = {
  allStarsAmount: 3,
  containerProps: {},
};

StarsGroup.propTypes = {
  allStarsAmount: number,
  completedStarsAmount: number.isRequired,
  containerProps: object,
};

export default withFlexProps(StarsGroup);
