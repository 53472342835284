import theme from '../../styles/theme';

export const styles = {
  container: {
    alignItems: 'center',
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(17, 17, 17, 0.66)',
    color: theme.colors.white[100],
    flexDirection: 'column',
    gap: 24,
    justifyContent: 'center',
    padding: '32px 16px',
    width: '100%',
  },
};
