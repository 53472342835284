import { Modal } from '@components';
import { useScreenSize } from '@hooks';
import { Button, Flex, theme, Typography } from '@styles';
import { bool, func, object } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DifficultyCompletionModal = ({
  difficulty,
  isModalVisible,
  setModalVisible,
}) => {
  const { i18n } = useTranslation();
  const { isTabletLG, isTabletSM } = useScreenSize();

  const onFinishPress = () => {
    setModalVisible(false);
  };

  return (
    <Modal
      innerContainerProps={{ height: '100%' }}
      isVisible={isModalVisible}
      modalContainerProps={{
        backgroundColor: theme.colors.white[5],
        border: 'none',
        borderRadius: isTabletLG ? '16px' : '4px',
        ...(isTabletSM && { height: '90%' }),
        width: isTabletSM ? '100%' : '70%',
      }}
      modalMaxHeight="100%"
      modalMaxWidth="100%"
      setModalVisible={setModalVisible}
      withBackdropNoise
      withCloseIcon={false}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        pb={16}
        pt={isTabletSM ? 40 : 80}
        px={isTabletSM ? 16 : 40}
        width="100%"
      >
        <Flex
          flexDirection="column"
          gap={isTabletSM ? 32 : 24}
          margin={isTabletSM ? 'auto 0' : 0}
          width="100%"
        >
          <Typography
            color={theme.colors.white[100]}
            textAlign={isTabletSM ? 'start' : 'center'}
            variant={isTabletSM ? 'BN-bold-s32-lh28' : 'BN-bold-s64-lh77'}
          >
            {i18n.t('Scenario.PassedDifficulty.Title')}{' '}
            <Typography
              color={theme.colors.schoolBusYellow[100]}
              display="inline-block"
              textAlign="left"
              variant={isTabletSM ? 'BN-bold-s32-lh28' : 'BN-bold-s64-lh77'}
            >
              {difficulty?.name}
            </Typography>{' '}
            !
          </Typography>
          <Typography
            color={theme.colors.white[80]}
            textAlign="left"
            variant={
              isTabletSM ? 'MS-semiregular-s18-lh22' : 'MS-semiregular-s28-lh29'
            }
          >
            {i18n.t('Scenario.PassedDifficulty.Subtitle')}
          </Typography>
          <Typography
            color={theme.colors.white[80]}
            textAlign="left"
            variant={
              isTabletSM ? 'MS-semiregular-s18-lh22' : 'MS-semiregular-s28-lh29'
            }
          >
            {i18n.t('Scenario.PassedDifficulty.ButtonSubtitle')}
          </Typography>
        </Flex>
        <Button
          m={isTabletSM ? 0 : 12}
          onClick={onFinishPress}
          variant="textButton"
        >
          <Typography
            color={theme.colors.white[100]}
            variant="MS-bold-s16-lh25"
          >
            {i18n.t('Scenario.PassedDifficulty.Button')}
          </Typography>
        </Button>
      </Flex>
    </Modal>
  );
};

DifficultyCompletionModal.propTypes = {
  difficulty: object,
  isModalVisible: bool.isRequired,
  setModalVisible: func.isRequired,
};

DifficultyCompletionModal.defaultProps = {
  difficulty: {},
};

export default DifficultyCompletionModal;
