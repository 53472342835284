import { capitalizeFirstLetter } from './string';

export const checkIfFormStateValueIsAccessibleByInputName = ({
  formStateValue,
  inputName,
}) => {
  if (!formStateValue) {
    return false;
  }

  const traversedNames = inputName?.split('.');
  const traversedFormStateValue = formStateValue[traversedNames[0]];

  if (traversedNames.length === 1 && formStateValue[traversedNames[0]]) {
    return !!formStateValue[traversedNames[0]];
  }

  return checkIfFormStateValueIsAccessibleByInputName({
    formStateValue: traversedFormStateValue,
    inputName: traversedNames.slice(1).join('.'),
  });
};

export const getErrorNotificationText = (errors) => {
  const validationErrorMessages = Object.keys(errors).reduce(
    (errorMessages, name) =>
      errors[name].type !== 'server'
        ? [...errorMessages, errors[name].message]
        : errorMessages,
    []
  );

  switch (true) {
    case validationErrorMessages.length === 0 && !!errors.server:
      return errors.server.message;
    case validationErrorMessages.length === 1:
      return capitalizeFirstLetter(validationErrorMessages[0]);
    case validationErrorMessages.length > 1:
      return 'Auth.Validation.MoreThanTwoFieldsWrong';
    default:
      return '';
  }
};
