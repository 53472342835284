import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import { store } from './store/store';
import commonEn from './translations/en.json';
import commonUa from './translations/ua.json';

const resources = {
  en: {
    translation: commonEn,
  },
  ua: {
    translation: commonUa,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: true,
    },
    lng: store.getState().authSlice.language,
    resources,
  });

export default i18n;
