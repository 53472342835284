import { withFlexProps } from '@hocs';
import { theme } from '@styles';
import styled, { css } from 'styled-components';
import { border, color, flexbox, layout, position, space } from 'styled-system';

const EllipseGradient = styled.div`
  ${({ customColor }) => css`
    display: flex;
    width: 433px;
    height: 789px;
    flex-shrink: 0;
    border-radius: 789px;
    pointer-events: none;
    background:
      linear-gradient(
          to bottom right,
          ${customColor || theme.colors.limeGreen} 0%,
          rgba(54, 184, 20, 0) 50%
        )
        bottom right / 50% 50% no-repeat,
      linear-gradient(
          to bottom left,
          ${customColor || theme.colors.limeGreen} 0%,
          rgba(54, 184, 20, 0) 50%
        )
        bottom left / 50% 50% no-repeat,
      linear-gradient(
          to top left,
          ${customColor || theme.colors.limeGreen} 0%,
          rgba(54, 184, 20, 0) 50%
        )
        top left / 50% 50% no-repeat,
      linear-gradient(
          to top right,
          ${customColor || theme.colors.limeGreen} 0%,
          rgba(54, 184, 20, 0) 50%
        )
        top right / 50% 50% no-repeat;
    filter: blur(72px);

    ${border};
    ${color};
    ${flexbox};
    ${layout};
    ${position};
    ${space};
  `}
`;

export default withFlexProps(EllipseGradient);
