/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-extraneous-dependencies */
import '@maptiler/sdk/dist/maptiler-sdk.css';
import './MapPage.css';

import {
  ControlButtons,
  LevelsIndicator,
  ScenarioCardModal,
} from '@components';
import { useBackgroundAudioSwitch, useScreenSize } from '@hooks';
import * as maptilersdk from '@maptiler/sdk';
import { scenarioApi } from '@store';
import { Flex, theme, Typography } from '@styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';

import {
  DEFAULT_MAP_CONFIG,
  IS_TEST_MODE,
  MAP_CONTAINER_ID,
  MIN_ZOOM_FOR_MOBILE,
  NATO_MARKERS_ARRAY_WITH_CONFIG,
  SCENARIO_MARKERS_ARRAY_WITH_CONFIG,
} from './MapPage.constants';
import { scenariosMockResponseForSergeant } from './MapPage.mocks';
import {
  drawLinesBetweenScenarioMarkers,
  drawNatoMarkers,
  drawScenarioMarkers,
  getScenariosMetrics,
  getUnavailableScenarioPopupHtml,
} from './MapPage.utils';

const scenariosMockResponse = scenariosMockResponseForSergeant;

const scrollMapToCurrentScenario = ({ levels, map }) => {
  const currentScenario = levels.find((level) => level.is_current);

  const coordinatesOfCurrentScenarioMarker =
    SCENARIO_MARKERS_ARRAY_WITH_CONFIG.find(
      (marker) => marker.levelNumber === currentScenario.level_number
    )?.coordinates;

  if (coordinatesOfCurrentScenarioMarker) {
    map.jumpTo({ center: coordinatesOfCurrentScenarioMarker });
  }
};

const MapPage = () => {
  const { i18n, t } = useTranslation();
  const { isTabletLG } = useScreenSize();

  const { language } = useSelector((state) => state.authSlice);
  const { triggerAudio } = useBackgroundAudioSwitch();

  const location = useLocation();

  const [currentScenario, setCurrentScenario] = useState(null);
  const [isScenarioModalOpen, setIsScenarioModalOpen] = useState(false);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [isRulesModalOpen, setIsRulesModalOpen] = useState(false);
  const [
    wasRulesModalOpenedAutomatically,
    setWasRulesModalOpenedAutomatically,
  ] = useState(false);

  const { refetch, scenarios } = scenarioApi.useGetScenariosQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ data }) => ({
      scenarios: data?.data?.levels,
    }),
  });

  const scenariosRef = useRef(scenarios);

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';

    return () => {
      document.documentElement.style.overflow = 'auto';
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    scenariosRef.current = scenarios;
  }, [scenarios]);

  useEffect(() => {
    refetch();
  }, [i18n.language, language]);

  useEffect(() => {
    if (scenarios?.length > 0) {
      // eslint-disable-next-line no-use-before-define
      renderMap();
    }
  }, [scenarios?.length]);

  useEffect(() => {
    if (!scenarios?.length) {
      return;
    }

    if (location?.state?.showRulesModal) {
      const didUserCompleteAtLeastOneScenario = scenarios.find(
        (scenario) => scenario.is_passed
      );

      const shouldRulesModalBeOpenedAutomatically =
        !wasRulesModalOpenedAutomatically && !didUserCompleteAtLeastOneScenario;

      if (shouldRulesModalBeOpenedAutomatically) {
        setIsRulesModalOpen(true);
        setWasRulesModalOpenedAutomatically(true);
      }
    }
  }, [location?.state?.showRulesModal, scenarios]);

  const handleNextScenarioOpen = (scenario) => {
    setCurrentScenario({
      ...scenario,
      subtitle: `${i18n.t('Scenario.Title')} ${scenario?.level_number}`,
    });
    setIsScenarioModalOpen(true);
  };

  const onAvailableScenarioPressClosure = (marker) => () => {
    const clickedScenario = scenariosRef.current.find(
      (scenario) => scenario.level_number === marker?.levelNumber
    );

    handleNextScenarioOpen(clickedScenario);
  };

  const onUnavailableScenarioPressDoubleClosure = (map) => (marker) => () => {
    let popup;
    // SetTimeout is needed otherwhise popup won't open since it will interact with initial click
    setTimeout(() => {
      popup = new maptilersdk.Popup({
        className: 'popup',
        closeButton: false,
        closeOnClick: true,
        closeOnMove: true,
      })
        .setLngLat(marker.coordinates)
        .setHTML(getUnavailableScenarioPopupHtml(t('Map.NeedPass')))
        .addTo(map);
    }, 100);

    // Automatically hide popup after some time
    setTimeout(() => {
      try {
        popup?.remove();
      } catch (error) {
        // console.log('Error removing popup:', error);
      }
    }, 2000);
  };

  const handlePlayButtonClick = () => {
    const nextMainScenario = scenarios.find((scenario) => scenario.is_current);

    let scenarioToOpen = nextMainScenario;

    /* 
      If current scenario is already passed it means all main scenarios are finished
      Only additional ones are left
    */
    if (nextMainScenario.is_passed) {
      const nextAdditionalScenario = scenarios?.filter(
        (scenario) =>
          scenario?.is_available &&
          !scenario?.is_passed &&
          !scenario?.is_current
      )?.[0];

      if (nextAdditionalScenario) {
        scenarioToOpen = nextAdditionalScenario;
      }
    }

    handleNextScenarioOpen(scenarioToOpen);
  };

  const renderMap = async () => {
    const map = new maptilersdk.Map({
      ...DEFAULT_MAP_CONFIG,
      ...(isTabletLG && { minZoom: MIN_ZOOM_FOR_MOBILE }),
      navigationControl: IS_TEST_MODE,
    });
    const levels = IS_TEST_MODE ? scenariosMockResponse.data.levels : scenarios;

    // Wait for map to load until performing any further actions
    await map.onLoadAsync();

    // Show coordinates in test mode to simplify debugging
    if (IS_TEST_MODE) {
      map.on('mousemove', (e) => {
        document.getElementById('info').innerHTML =
          `${JSON.stringify(e.point)}<br />${JSON.stringify(e.lngLat.wrap())}`;
      });

      // Copy coords to clipboard on click to create points for markers easier
      map.on('click', async (e) => {
        const coordinates = e.lngLat.wrap();

        await navigator.clipboard.writeText(
          `${coordinates.lng}, ${coordinates.lat}`
        );
      });
    }

    drawScenarioMarkers({
      availableMarkerClickHandler: onAvailableScenarioPressClosure,
      levels,
      map,
      markers: SCENARIO_MARKERS_ARRAY_WITH_CONFIG,
      unavailableMarkerClickHandler:
        onUnavailableScenarioPressDoubleClosure(map),
    });

    drawNatoMarkers({
      levels,
      map,
      markers: NATO_MARKERS_ARRAY_WITH_CONFIG,
    });

    drawLinesBetweenScenarioMarkers({
      levels,
      map,
      markers: SCENARIO_MARKERS_ARRAY_WITH_CONFIG,
    });

    setIsMapLoading(false);
    scrollMapToCurrentScenario({ levels, map });
  };

  const handleRulesModalClose = () => {
    setIsRulesModalOpen(false);
    triggerAudio();
  };

  const { passedScenariosAmount, totalScenariosAmount } = getScenariosMetrics({
    scenarios,
  });

  const isMapHidden = isMapLoading || isScenarioModalOpen || isRulesModalOpen;

  return (
    <Flex flexDirection="column" height="100%" overflowX="hidden" width="100%">
      {IS_TEST_MODE && <pre id="info" />}
      {!isMapLoading && (
        <Flex
          flexDirection="column"
          position="absolute"
          top={40}
          width="100%"
          zIndex={2}
        >
          <ControlButtons
            containerProps={{
              position: 'absolute',
              right: isTabletLG ? '32px' : '64px',
              top: isTabletLG ? '8px' : 0,
            }}
            controls={['info', 'sound', 'language']}
            isRulesModalVisible={isRulesModalOpen}
            onModalClose={handleRulesModalClose}
            setRulesModalVisible={setIsRulesModalOpen}
          />
          <Typography
            color={theme.colors.white[100]}
            marginLeft={isTabletLG ? '32px' : 0}
            marginTop={isTabletLG ? '19px' : 0}
            textAlign={isTabletLG ? 'none' : 'center'}
            variant={isTabletLG ? 'BN-bold-s32-lh28' : 'BN-bold-s56-lh67'}
          >
            {i18n.t('Map.Title')}
          </Typography>
          {!isTabletLG && (
            <Typography
              color={theme.colors.white[100]}
              textAlign="center"
              variant="MS-regular-s24-lh37"
            >
              {i18n.t('Map.Subtitle')}
            </Typography>
          )}
        </Flex>
      )}
      <div className={isMapHidden ? 'map-hidden' : ''} id={MAP_CONTAINER_ID} />
      {isMapLoading && (
        <div className="map-loading">
          <ClipLoader color="#FFF504" size="60px" speedMultiplier={0.7} />
        </div>
      )}
      <ScenarioCardModal
        isModalVisible={isScenarioModalOpen}
        scenario={currentScenario}
        setModalVisible={setIsScenarioModalOpen}
      />
      {!isMapLoading && (
        <LevelsIndicator
          allLevelsAmount={totalScenariosAmount}
          completedLevelsAmount={passedScenariosAmount}
          onPlayButtonClick={handlePlayButtonClick}
        />
      )}
    </Flex>
  );
};

export default MapPage;
