import { api } from '../api';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    signIn: build.mutation({
      query: ({ password, username }) => ({
        body: {
          password,
          username,
        },
        method: 'POST',
        url: 'login',
      }),
    }),
    signOut: build.mutation({
      query: () => ({
        method: 'POST',
        url: 'logout',
      }),
    }),
    signUp: build.mutation({
      query: ({ password, username }) => ({
        body: {
          password,
          username,
        },
        method: 'POST',
        url: 'register',
      }),
    }),
  }),
  reducerPath: 'authApi',
});
