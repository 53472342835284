import Level1 from '@pngs/level1.png';
import Level2 from '@pngs/level2.png';
import Level3 from '@pngs/level3.png';

export const onboardingPageLayoutByVariant = {
  desktop: {
    px: 104,
    py: 41,
  },
  laptop: {
    px: 44,
    py: 21,
  },
  mobile: {
    px: 16,
    py: 16,
  },
  tablet: {
    px: 16,
    py: 16,
  },
};

export const levelLayoutByVariant = {
  Офіцер: {
    image: {
      mobileStyle: {},
      src: Level3,
    },
    subtitle:
      'Випробуй себе на полі бою стратегій та рішень, де кожен крок важливий',
    title: 'ОФІЦЕР',
  },
  Сержант: {
    image: {
      mobileStyle: { transform: 'rotate(90deg)' },
      src: Level2,
    },
    subtitle:
      'Керуй викликами, як справжній лідер, середній шлях до майстерності',
    title: 'СЕРЖАНТ',
  },
  Солдат: {
    image: {
      mobileStyle: { transform: 'rotate(90deg)' },
      src: Level1,
    },
    subtitle: 'Крокуй з впевненістю, навіть коли шлях здається легким',
    title: 'СОЛДАТ',
  },
};
