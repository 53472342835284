import { useBackgroundAudioSwitch } from '@hooks';
import React, { useEffect, useRef } from 'react';

const audioUrl =
  'https://mod-media-s3-bucket-dev.s3.eu-north-1.amazonaws.com/audio.mp3';

const BackgroundAudioPlayer = () => {
  const audioPlayer = useRef();
  const { clearAudioTrigger, isAudioPlaying, isAudioTriggered, turnAudioOff } =
    useBackgroundAudioSwitch();

  useEffect(
    () => () => {
      turnAudioOff();
    },
    []
  );

  const play = () => {
    audioPlayer.current?.play().catch((error) => {
      // eslint-disable-next-line no-console
      console.log('Error playing audio:', error);
    });
  };

  const pause = () => audioPlayer.current?.pause();

  useEffect(() => {
    if (!audioPlayer.current) return;

    if (isAudioTriggered) {
      if (isAudioPlaying) {
        play();
      }

      clearAudioTrigger();
    }
  }, [isAudioTriggered, audioPlayer.current]);

  useEffect(() => {
    if (isAudioPlaying) {
      play();
    } else {
      pause();
    }
  }, [isAudioPlaying]);

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <audio ref={audioPlayer} id="audio" loop>
      <source src={audioUrl} type="audio/mpeg" />
    </audio>
  );
};

export default BackgroundAudioPlayer;
