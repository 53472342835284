import { ReactComponent as PasswordIcon } from '@svgs/lock.svg';
import { ReactComponent as EmailIcon } from '@svgs/mail.svg';
import { ReactComponent as FirstNameIcon } from '@svgs/military-tech.svg';
import { ReactComponent as GenderIcon } from '@svgs/paragliding.svg';

export const inputLayoutByVariant = {
  email: {
    placeholder: 'Input.Email',
    svg: EmailIcon,
  },
  gender: {
    placeholder: 'Input.Gender',
    svg: GenderIcon,
  },
  newPassword: {
    placeholder: 'Input.NewPassword',
    svg: PasswordIcon,
  },
  oldPassword: {
    placeholder: 'Input.OldPassword',
    svg: PasswordIcon,
  },
  password: {
    placeholder: 'Input.Password',
    svg: PasswordIcon,
  },
  repeatNewPassword: {
    placeholder: 'Input.RepeatNewPassword',
    svg: PasswordIcon,
  },
  repeatPassword: {
    placeholder: 'Input.RepeatPassword',
    svg: PasswordIcon,
  },
  username: {
    placeholder: 'Input.Username',
    svg: FirstNameIcon,
  },
};
