import { VALIDATION } from '@constants';
import { string } from 'yup';

const shapes = {
  email: string()
    .trim()
    .required(VALIDATION.ERROR_MESSAGE.EMAIL.REQUIRED)
    .email(VALIDATION.ERROR_MESSAGE.EMAIL.INVALID)
    .matches(VALIDATION.REG_EXP.EMAIL, {
      excludeEmptyString: true,
      message: VALIDATION.ERROR_MESSAGE.EMAIL.INVALID,
    })
    .max(
      VALIDATION.MAX_LENGTH.EMAIL,
      VALIDATION.ERROR_MESSAGE.EMAIL.MAX_LENGTH_EXCEEDED
    ),
  password: string()
    .trim()
    .required(VALIDATION.ERROR_MESSAGE.PASSWORD.REQUIRED)
    .matches(VALIDATION.REG_EXP.PASSWORD, {
      excludeEmptyString: true,
      message: VALIDATION.ERROR_MESSAGE.PASSWORD.INVALID,
    })
    .max(
      VALIDATION.MAX_LENGTH.PASSWORD,
      VALIDATION.ERROR_MESSAGE.PASSWORD.MAX_LENGTH_EXCEEDED
    ),
  text: string().trim().required(),
};

export default shapes;
