import { Typography } from '@styles';
import React from 'react';

import Stepper from '../Stepper/Stepper';

export const getRuleBlocks = ({ isTabletSM, t }) => [
  {
    rules: [
      {
        rule: (
          <div>
            <Typography
              variant={
                isTabletSM
                  ? 'MS-semiregular-s16-lh21'
                  : 'MS-semiregular-s20-lh22'
              }
            >
              {t('RulesModal.Structure.1')}
            </Typography>
            <ul style={{ padding: '5px 15px' }}>
              <li>
                <Typography
                  variant={
                    isTabletSM
                      ? 'MS-semiregular-s16-lh21'
                      : 'MS-semiregular-s20-lh22'
                  }
                >
                  {t('RulesModal.Structure.1.1')}
                </Typography>
              </li>
              <li>
                <Typography
                  variant={
                    isTabletSM
                      ? 'MS-semiregular-s16-lh21'
                      : 'MS-semiregular-s20-lh22'
                  }
                >
                  {t('RulesModal.Structure.1.2')}
                </Typography>
              </li>
            </ul>
          </div>
        ),
      },
      { rule: t('RulesModal.Structure.2') },
    ],
    title: t('RulesModal.Structure.Title'),
  },
  {
    rules: [
      { rule: t('RulesModal.AnswersChoosing.1') },
      { rule: t('RulesModal.AnswersChoosing.2') },
    ],
    title: t('RulesModal.AnswersChoosing.Title'),
  },
  {
    rules: [
      {
        rule: t('RulesModal.ScenarioNavigation.1'),
      },
      {
        rule: (
          <Stepper
            containerProps={{ justifyContent: 'center' }}
            currentStep={1}
            isDisabled
            onStepChange={() => {}}
            size={24}
            steps={[1, 2, 3]}
          />
        ),
      },
    ],
    title: t('RulesModal.ScenarioNavigation.Title'),
  },
  {
    rules: [
      { rule: t('RulesModal.LevelsPassing.1') },
      {
        rule: t('RulesModal.LevelsPassing.2'),
      },
    ],
    title: t('RulesModal.LevelsPassing.Title'),
  },
  {
    rules: [{ rule: t('RulesModal.Illustrations.1') }],
    title: t('RulesModal.Illustrations.Title'),
  },
  {
    rules: [
      {
        rule: t('RulesModal.AboutCharacter.1'),
      },
    ],
    title: t('RulesModal.AboutCharacter.Title'),
  },
];
