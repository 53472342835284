import styled, { css } from 'styled-components';
import {
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  variant,
} from 'styled-system';

const Button = styled.button`
  ${({
    borderProps,
    borderRadius,
    disabled,
    gap,
    height,
    theme: { colors },
    whiteSpace,
  }) => css`
    cursor: pointer;
    outline: none;

    display: flex;
    align-items: center;
    justify-content: center;

    ${whiteSpace && `white-space: ${whiteSpace};`}
    ${gap && `gap: ${gap}px;`}

    height: ${height || '69px'};
    border-radius: ${borderRadius || '8px'};

    ${variant({
      variants: {
        primary: {
          backgroundColor: disabled
            ? colors.scorpion[70]
            : colors.schoolBusYellow[100],
          border: 'none',
          color: 'black',
        },
        quaternary: {
          backgroundColor: 'transparent',
        },
        secondary: {
          backgroundColor: colors.white[10],
          border: '2px solid #ffffff33',
          color: colors.white[100],
        },
        tertiary: {
          backgroundColor: colors.greenKelp[100],
        },
        textButton: {
          backgroundColor: 'transparent',
          border: borderProps || 'none',
        },
      },
    })}

    &:hover {
      ${variant({
        variants: {
          primary: {
            backgroundColor: disabled ? colors.scorpion[70] : colors.gorse,
            color: 'black',
          },
          quaternary: {
            backgroundColor: colors.white[10],
          },
          secondary: {
            backgroundColor: colors.white[10],
            border: '2px solid #ffffff66',
            color: colors.white[100],
          },
          tertiary: {
            backgroundColor: colors.greenKelp[100],
            border: '2px solid #847200',
          },
          textButton: {
            backgroundColor: 'transparent',
            border: borderProps || 'none',
          },
        },
      })}
    }

    &:active {
      ${variant({
        variants: {
          primary: {
            backgroundColor: disabled ? colors.scorpion[70] : colors.galliano,
            color: 'black',
          },
          quaternary: {
            backgroundColor: 'transparent',
          },
          secondary: {
            backgroundColor: colors.white[30],
            border: '2px solid #ffffff66',
            color: colors.white[100],
          },
          tertiary: {
            backgroundColor: colors.greenKelp[100],
          },
          textButton: {
            backgroundColor: 'transparent',
            border: borderProps || 'none',
          },
        },
      })}
    }

    ${border};
    ${color};
    ${flexbox};
    ${layout};
    ${position};
    ${space};
  `}
`;

export default Button;
