import { api } from '../api';

export const scenarioApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDifficultyLevels: build.query({
      query: () => ({
        url: 'difficulty-level',
      }),
    }),
    getScenarioById: build.query({
      query: ({ id }) => ({
        url: `scenario/${id}`,
      }),
    }),
    getScenarios: build.query({
      providesTags: ['scenarios'],
      query: () => ({
        url: 'level',
      }),
    }),
    validateStepById: build.mutation({
      query: ({ data, id }) => ({
        body: data,
        method: 'POST',
        url: `scenario/step/${id}/validate`,
      }),
    }),
  }),
  reducerPath: 'scenarioApi',
});
