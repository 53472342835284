const VALIDATION = {
  ERROR_MESSAGE: {
    EMAIL: {
      INVALID: 'Validation.InvalidEmail',
      MAX_LENGTH_EXCEEDED: 'Validation.EmailMaxLenght',
      REQUIRED: 'Validation.EmailRequired',
    },
    PASSWORD: {
      DO_NOT_MATCH: 'Validation.PasswordsNotMatch',
      INVALID: 'Validation.InvalidPassword',
      MATCH: 'Validation.PasswordMatch',
      MAX_LENGTH_EXCEEDED: 'Validation.PasswordMaxLenght',
      REQUIRED: 'Validation.PasswordRequired',
    },
  },
  MAX_LENGTH: {
    EMAIL: 64,
    PASSWORD: 20,
  },
  REG_EXP: {
    EMAIL: /^|\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
    PASSWORD: /^.{6,}$/,
  },
};

export default VALIDATION;
