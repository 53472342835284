import { EllipseGradient, SignInForm, SignUpForm } from '@components';
import { routes } from '@constants';
import { useBackgroundAudioSwitch, useScreenSize } from '@hooks';
import Main from '@pngs/main.png';
import NoiseImage from '@pngs/Noise5.png';
import NoiseImageMobile from '@pngs/NoiseMobile10.png';
import { authSlice } from '@store';
import { Flex, Image } from '@styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { mainPageLayoutByVariant } from './MainPage.layoutProps';

const MainPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { device, isDesktop, isTabletSM } = useScreenSize();
  const layout = mainPageLayoutByVariant[device || 'desktop'];

  const [authModalState, setAuthModalState] = useState('signIn');

  const { turnAudioOff, turnAudioOn } = useBackgroundAudioSwitch();

  useEffect(() => {
    turnAudioOff();
  }, []);

  const authorize = (isOnboardingPassed) => {
    dispatch(authSlice.actions.setOnboardingStatus(isOnboardingPassed));
    if (isOnboardingPassed) {
      turnAudioOn();
      navigate(routes.map, { state: { showRulesModal: true } });
    } else {
      navigate(routes.onboarding);
    }
  };

  const formByState = {
    signIn: (
      <SignInForm
        onSignInButtonClick={authorize}
        setAuthModalState={setAuthModalState}
      />
    ),
    signUp: (
      <SignUpForm
        onSignUpButtonClick={authorize}
        setAuthModalState={setAuthModalState}
      />
    ),
  };

  return (
    <Flex
      backgroundBlendMode="overlay"
      backgroundImage={isTabletSM ? NoiseImageMobile : NoiseImage}
      backgroundSize="100%"
    >
      <Flex
        {...(!isTabletSM && { height: '100vh' })}
        justifyContent="flex-end"
        position="relative"
        width="100%"
        {...layout}
      >
        {!isTabletSM && (
          <Flex bottom={10} left={0} position="absolute">
            <EllipseGradient
              left={-140}
              position="absolute"
              top={-100}
              zIndex={-1}
            />
            <Image
              height={isDesktop ? '98vh' : '90vh'}
              mixBlendMode="overlay"
              src={Main}
              zIndex={-1}
            />
          </Flex>
        )}
        {formByState[authModalState]}
      </Flex>
    </Flex>
  );
};

export default MainPage;
