import { FadeInOut } from '@components';
import { useScreenSize } from '@hooks';
import { Flex, theme, Typography } from '@styles';
import { bool, number, shape, string } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { scenarioPageLayoutByVariant } from '../ScenarioPage.layoutProps';

const TitleSection = ({ isCardShown, scenario }) => {
  const i18n = useTranslation();
  const { device, isTabletLG, isTabletSM } = useScreenSize();
  const layout = scenarioPageLayoutByVariant[device || 'desktop'];

  return (
    <FadeInOut
      alignItems="center"
      duration={500}
      justifyContent="center"
      show={isCardShown}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: isTabletLG ? '100%' : '70%',
      }}
    >
      <Flex {...layout} style={{ minHeight: '650px' }} width="100%">
        <Flex
          alignItems="center"
          backgroundColor={theme.colors.white[5]}
          borderRadius={isTabletLG ? 24 : 4}
          flexDirection="column"
          justifyContent="center"
          px={isTabletLG ? 16 : 24}
          py={24}
          width="100%"
        >
          <Typography
            color={theme.colors.white[80]}
            textAlign="center"
            variant={isTabletSM ? 'BN-bold-s40-lh35' : 'BN-bold-s96-lh105'}
          >
            {scenario?.title}
          </Typography>
          <Flex height={30} />
          <Typography
            color={theme.colors.vividYellow[80]}
            textAlign="center"
            variant={
              isTabletSM ? 'MS-semibold-s22-lh34' : 'MS-semibold-s32-lh50'
            }
          >
            {i18n.t('Scenario.Title')} {scenario?.level_number}
          </Typography>
        </Flex>
      </Flex>
    </FadeInOut>
  );
};

TitleSection.propTypes = {
  isCardShown: bool.isRequired,
  scenario: shape({
    level_number: number,
    title: string,
  }).isRequired,
};

export default TitleSection;
